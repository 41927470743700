import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { interval } from 'rxjs';
import { TrainingService } from '../training-service/training.service';
import { PurchaseService } from '../purchase-service/purchase.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import Bugsnag from '@bugsnag/js'
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';
import { PurchaseTracking } from 'app/enums/purchase_tracking.enum';

const TABLET_WIDTH = 767;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  private lastWatchedDate: Date = new Date();
  public isDarkMode = false;
  public isWebVersion = false;
  public streakHelp = false;
  public newTraining = false;
  public deckSettings = false;
  public disconnected = false;
  public routingName: string;
  public installPrompt = null;
  public installTriggered: boolean = false;
  public showReview = true;
  public seenReview = false;
  public filterargs = 'all';
  public updatePrompt = false;
  public updateVersion = '2.6.2';
  public showUpdatePrompt = false;
  public showUpdatePromptBool = true;
  public reloadAudio = false;
  public freeStoriesInfo = false;
  public trackingString = PurchaseTracking.EMPTY;

  constructor(
    private platform: Platform,
    private trainingService: TrainingService,
    private purchaseService: PurchaseService,
    private _sqliteService: SQLiteService,
    private http: HttpClient
  ) {
    this.setWebVersion();
  }

  setWebVersion() {
    const isWeb = !this.platform.is('cordova');
    if (isWeb && !environment.mockNative) {
      this.isWebVersion = true;
    }
  }

  watchDate(): void {
    interval(environment.general.schedulerDateInterval).subscribe(() => {
      const now = new Date();
      if (
        now.getDay() != this.lastWatchedDate.getDay()
        || now.getMonth() != this.lastWatchedDate.getMonth()
        || now.getFullYear() != this.lastWatchedDate.getFullYear()
      ) {
        this.lastWatchedDate = now;
        this.trainingService.saveStack(true);
        this.purchaseService.checkVoucherValid();
      }
    });
  }

  public isMobile(): boolean {
    return this.platform.width() < TABLET_WIDTH;
  }

  public isNative(): boolean {
    return Capacitor.isNative;
  }

  public getPlatform(): string {
    return Capacitor.platform;
  }

  public checkAppearance(): void {
    window.matchMedia('(prefers-color-scheme: dark)').addListener((mediaQuery) => {
      this._sqliteService.getItem(StorageKeys.APPEARANCE).then((val: string) => {
        if (val) {
          if (val == 'system') this.setSystemAppearance();
        }
      });
    });

    this._sqliteService.getItem(StorageKeys.APPEARANCE).then((val: string) => {
      if (val) {
        if (val == 'dark') {
          this.toggleDarkMode(true);
          this.isDarkMode = true;
        }
        if (val == 'light') {
          this.toggleDarkMode(false);
          this.isDarkMode = false;
        }
        if (val == 'system') this.setSystemAppearance();
      } else {
        //this.setSystemAppearance();
        this.storeAppearance('light');
      }
    });
  }

  public setSystemAppearance(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.storeAppearance('system');
    if (prefersDark.matches) {
      document.body.classList.toggle('dark', true);
      this.isDarkMode = true;
    } else {
      document.body.classList.toggle('dark', false);
      this.isDarkMode = false;
    }
  }

  public toggleDarkMode(shouldAdd: boolean): void {
    document.body.classList.toggle('dark', shouldAdd);
    if (shouldAdd) {
      this.storeAppearance('dark');
      this.isDarkMode = true;
    } else {
      this.storeAppearance('light');
      this.isDarkMode = false;
    }
  }

  private storeAppearance(value: string): void {
    this._sqliteService.setItem(StorageKeys.APPEARANCE, value);
  }

  public logErrorOnServer(message: string) {
    this.http.post(`${environment.api}/debug`, {
      message: message
    }).subscribe((res) => { })
  }

  public getBugReportID() {
    return Bugsnag.getUser().id;
  }
}
