import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Article } from '../../interfaces/article';
import { Categorie } from '../../interfaces/categorie';
import { environment } from '../../../environments/environment';
import { FlashFiction, Story, Dialogue, WordResultMap } from '../../interfaces/story';
import { AuthService } from '@services/auth-service/auth.service';
import { Subject, timer } from 'rxjs';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  public big_view = false;
  public storyPreloaded: Story = {} as Story;
  public storyPreloadedOnboarding: any = [];
  public revisionTab = "quiz";
  public quizLast = false;
  public quizTab = false;
  // FILTER
  public tags: number[] = [];
  public level: string[] = [];
  public filterLoader = false;
  public filterResults: Article[] = [] as Article[];
  public favoritesArticles: Story[] = [];
  public LearnedArticles: Story[] = [];
  public filterResultsLoading = true;
  public filterResultsEmpty = false;
  public categories: Categorie[] = [] as Categorie[];
  public articlesSlider: Story[] = [];
  public articlesSilderPremium: Story[] = [];
  public wpTotalPages: number;
  public wpCurrentPage = 1;
  public article_id: any;
  public freeArticlesOpened = 0;
  public shuffleToggle = false;
  public Langchanged = false

  private amountStoryLoading = 30;
  public showHomeSlider = true;

  levelCollection: Story[] = [];

  public storyMap: Map<string, Story[]> = new Map<string, Story[]>();
  public flashfictionMap: Map<string, FlashFiction[]> = new Map<string, FlashFiction[]>();
  public dialogueMap: Map<string, Dialogue[]> = new Map<string, Dialogue[]>();
  public storyCollection: Story[] = [] as Story[];
  public selectedFilterLevel: string = 'all';
  public isOnboardingKeywordAdded = false;
  public eventUpdateHomeSlider: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private _sqliteService: SQLiteService
  ) { }

  public initAppWithStories(language: string, irgnoreSelected = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.initStoryMap();
      this.getStories(language);
      let levels = ["A1", "A2", "B1", "B2", "C1"];
      if (language == 'ja') levels = ["N2", "N3", "N4", "N5"];
      if (language == 'zh-hans') levels = ["HSK1", "HSK2", "HSK3", "HSK4"];

      this._sqliteService.getItem(StorageKeys.APP_STARTS).then((val: number) => {
        if (!irgnoreSelected && val == 1 || (!val || val == null)) {
          this.getSelectedStories(language);
          this.getFlashFictionStories(language);
        } else {
          this.getFreeStories(language);
          this.getFlashFictionStories(language);
        }
      })


      for await (const level of levels) {
        const data = await this.getLevelCollection(language, level, 0);
        this.storyMap.set(level, data);
      }
      resolve(true);
    });
  }

  public initStoryMap() {
    this.storyMap = new Map<string, Story[]>();
    this.flashfictionMap = new Map<string, FlashFiction[]>();
    this.dialogueMap = new Map<string, Dialogue[]>();
    this.storyMap.set('all', []);
    this.storyMap.set('free', []);
    this.storyMap.set('A1', []);
    this.storyMap.set('A2', []);
    this.storyMap.set('B1', []);
    this.storyMap.set('B2', []);
    this.storyMap.set('C1', []);
    this.storyMap.set('C2', []);
    this.storyMap.set('N2', []);
    this.storyMap.set('N3', []);
    this.storyMap.set('N4', []);
    this.storyMap.set('N5', []);
    this.flashfictionMap.set('published', []);
    this.flashfictionMap.set('classic', []);
    this.flashfictionMap.set('dialogue', []);
    this.flashfictionMap.set('heartwarming', []);
    this.dialogueMap.set('dialogue_stories', []);
  }

  public getDialogues(language: string): Promise<any> {
    const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/dialogues/app/published?language=${language}&fields=${fields.join(',')}`, {
        headers: this.authService.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (err) => {
        reject(err);
      });
    });
  }

  public async getFlashFictionStories(language: string) {
    if (!language) language = await this._sqliteService.getItem(StorageKeys.ORIGIN_LANGUAGE) as string;
    return new Promise((resolve, reject) => {
      const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
      this.http.get<any>(
        `${environment.api}/shortstories/app/published?language=${language}&fields=${fields.join(',')}`,
        {
          headers: this.authService.authHeader,
        },
      ).subscribe(async result => {
        this.flashfictionMap.set('published', result);
        // if (result.category == 'classic') this.flashfictionMap.set('classic',[result])
        this.flashfictionMap.set('classic', await this.getFlashFictionCategory(language, 'classic'));
        this.flashfictionMap.set('dialogue', await this.getFlashFictionCategory(language, 'dialogue'));
        this.flashfictionMap.set('heartwarming', await this.getFlashFictionCategory(language, 'heartwarming'));
        this.dialogueMap.set('dialogue_stories', await this.getDialogues(language));
        resolve(true);
      }, (err) => {
        reject(err);
      });
    });
  }

  public getFlashFictionCategory(language: string, category: string,): Promise<any> {
    // const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/shortstories/app/published?language=${language}&category=${category}`, {
        headers: this.authService.authHeader
      }).subscribe((data) => {
        // this.flashfictionMap.set(category, data.results)
        resolve(data);
      }, (err) => {
        reject(err);
      });
    });
  }

  public async getFreeStories(language: string) {
    if (!language) language = await this._sqliteService.getItem(StorageKeys.ORIGIN_LANGUAGE) as string;

    return new Promise((resolve, reject) => {
      const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
      this.http.get<any>(
        `${environment.api}/stories/app?skip=0&limit=${this.amountStoryLoading}&language=${language}&free=true&fields=${fields.join(',')}`,
        {
          headers: this.authService.authHeader,
        },
      ).subscribe(result => {
        this.storyMap.set('free', result.results);
        resolve(true);
      });
    });
  }

  public async getStories(language: string): Promise<any> {
    if (!language) language = await this._sqliteService.getItem(StorageKeys.ORIGIN_LANGUAGE) as string;
    return new Promise((resolve, reject) => {
      const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
      this.http.get<any>(
        `${environment.api}/stories/app?skip=0&limit=${this.amountStoryLoading}&language=${language}&fields=${fields.join(',')}`,
        {
          headers: this.authService.authHeader,
        },
      ).subscribe(result => {
        this.storyMap.set('all', result.results);
        this.setStoryCollection('all');
        this.pushToSlider(result.results);
        resolve(true);
      });
    })
  }

  public getSelectedStories(language: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(
        `${environment.api}/stories/app?language=${language}&tags=["first_session"]`,
        {
          headers: this.authService.authHeader,
        },
      ).subscribe(result => {

        for (let index = 0; index < result.results.length; index++) {
          const s = result.results[index];
          s.free = true;
        }
        this.storyMap.set('free', result.results);
        resolve(true);
      });
    });
  }

  public getStoryLevelCollection(language: string): Promise<Story[]> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/stories/app?levelCollection=true&language=${language}&fields=_id,level,free,titles,picture,schedule&levels=A1,A2,B1,B2,C1`, {
        headers: this.authService.authHeader
      }).subscribe((data) => {
        this.levelCollection = data;
        resolve(data);
      });
    });
  }

  getMoreArticles(language: string, ev?: { target: { complete: () => void; }; }): void {

    const collection = this.storyMap.get(this.selectedFilterLevel);
    console.log("COLLECTION", collection.length, this.amountStoryLoading, this.selectedFilterLevel, collection.length % this.amountStoryLoading);
    if ((collection.length % this.amountStoryLoading) != 0) {
      ev.target.complete();
      return;
    };

    if (this.selectedFilterLevel == 'all') {
      this.http.get(
        `${environment.api}/stories/app?skip=${collection.length}&limit=${this.amountStoryLoading}&language=${language}&fields=_id,level,free,text,titles,picture,schedule&levels=A1,A2,B1,B2,C1`, {
        headers: this.authService.authHeader
      },
      ).subscribe((data: any) => {
        if (ev) ev.target.complete();
        const colAll = this.storyMap.get('all');
        if (colAll) {
          this.storyMap.set('all', colAll.concat(data.results));
          this.setStoryCollection('all');
        }
      });
    } else {
      this.getLevelCollection(language, this.selectedFilterLevel, collection.length).then(data => {
        if (ev) ev.target.complete();
        const col = this.storyMap.get(this.selectedFilterLevel);
        if (col) {
          this.storyMap.set(this.selectedFilterLevel, col.concat(data));
          this.setStoryCollection(this.selectedFilterLevel);
        }
      });
    }
  }

  public setStoryCollection(level: string) {
    this.storyCollection = this.storyMap.get(level);
  }

  public getStory(id: any, firstSession = false) {
    return this.http.get<any>(`${environment.api}/stories/app/${id}?all=true${firstSession ? "&firstSession=true" : ""}`, {
      headers: this.authService.authHeader,
    });
  }

  public getStoryByStoryId(storyId: string, language: string) {
    return this.http.get<any>(`${environment.api}/stories/app/${storyId}?all=true&language=${language}`, {
      headers: this.authService.authHeader,
    });
  }

  public getShortStoryById(id: string, language: string) {
    return this.http.get<any>(`${environment.api}/shortstories/app/${id}?all=true&language=${language}`, {
      headers: this.authService.authHeader,
    });
  }
  public getShortStory(id: string) {
    return this.http.get<any>(`${environment.api}/shortstories/app/${id}`, {
      headers: this.authService.authHeader,
    });
  }

  public getDialogueStoryById(id: string, language: string) {
    return this.http.get<any>(`${environment.api}/dialogues/app/${id}?all=true&language=${language}`, {
      headers: this.authService.authHeader,
    });
  }

  public getDialogueStory(id: string) {
    return this.http.get<any>(`${environment.api}/dialogues/app/${id}`, {
      headers: this.authService.authHeader,
    });
  }

  public createCategoryArray(numbers: number[]): string {
    let x = '';

    numbers.forEach((number) => {
      this.categories.forEach((cat) => {
        if (cat.id == number) {
          x = x + ' ' + cat.name + ',';
        }
      });
    });

    x = x.substr(1);

    if (x.substr(x.length - 1) == ',') {
      x = x.substring(0, x.length - 1);
    }

    return x;
  }

  public async getFavorites(array: string[], languageCode: string, resetArray = true): Promise<void> {
    if (array.length == 0) return;
    languageCode = languageCode.toLowerCase();
    const chunkSize = 20;
    const chunks = this.chunkArray(array, chunkSize);

    if (resetArray) this.favoritesArticles = [];
    for (const chunk of chunks) {
      const data = await this.http.get<any>(`${environment.api}/stories/app?ids=${chunk.join(',')}&languageCode=${languageCode}`, {
        headers: this.authService.authHeader
      }).toPromise();
      this.favoritesArticles = [...this.favoritesArticles, ...data];
    }
  }

  private chunkArray(array: string[], chunkSize: number): string[][] {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  public async getLearned(array: string[], languageCode: string, resetArray = true): Promise<void> {
    if (array.length == 0) return;
    languageCode = languageCode.toLowerCase();
    const chunkSize = 20;
    const chunks = this.chunkArray(array, chunkSize);

    if (resetArray) this.LearnedArticles = [];

    for (const chunk of chunks) {
      const data = await this.http.get<any>(`${environment.api}/stories/app?ids=${chunk.join(',')}&languageCode=${languageCode}`, {
        headers: this.authService.authHeader
      }).toPromise();
      this.LearnedArticles = [...this.LearnedArticles, ...data];
    }
  }

  public updateArticleLearned(story: Story, status: boolean): void {
    if (status) this.LearnedArticles.unshift(story);
    for (let index = 0; index < this.storyCollection.length; index++) {
      const element = this.storyCollection[index];
      if (element._id == story._id) {
        element.isLearned = status;
        return;
      }
    }
  }

  public updateArticleFavorites(story: Story, status: boolean): void {
    if (status) this.favoritesArticles.unshift(story);
    for (let index = 0; index < this.storyCollection.length; index++) {
      const element = this.storyCollection[index];
      if (element._id == story._id) {
        element.isFav = status;
        return;
      }
    }
  }

  pushToSlider(data: Story[]): void {

    let countFree = 1;
    let countPremium = 1;

    this.articlesSilderPremium = [];
    this.articlesSlider = [];

    for (
      let index = 0;
      index < data.length &&
      (countFree <= environment.general.sliderFreeCount || countPremium <= environment.general.sliderPremiumCount);
      index++
    ) {
      const element: Story = data[index];
      if (countPremium <= environment.general.sliderPremiumCount) {
        this.pushArticleToSilder(element, true);
        countPremium++;
      }

      if (element.free) {
        this.pushArticleToSilder(element, false);
        countFree++;
      }
    }
  }

  private pushArticleToSilder(story: Story, premium: boolean): void {
    if (premium) this.articlesSilderPremium.push(story);
    else this.articlesSlider.push(story);
  }

  public getTitle(story: Story, language: string): string {
    if (!story.titles) return '';
    for (let index = 0; index < story.titles.length; index++) {
      const title = story.titles[index];
      if (title.language === language) return title.text;
    }

    if (language === 'eng') return this.getTitle(story, 'en');
    return "No Title found";
  }

  public findSentence(storyMap: WordResultMap[], wordIndex: number, story?: any): any {

    if (story && story.sentencesMeta && Object.hasOwn(story, "source")) {
      for (let index = 0; index < story.sentencesMeta.length; index++) {
        const element = story.sentencesMeta[index];
        if (element.start <= wordIndex && element.end >= wordIndex) {
          let sentence: WordResultMap[] = [];
          for (let index2 = 0; index2 < storyMap.length; index2++) {
            const wordEntry = storyMap[index2];
            if (index2 >= element.start && index2 <= element.end) {
              sentence.push(wordEntry);
            }
          }

          return { sentence: sentence, position: wordIndex - element.start, totalPosition: wordIndex };
        }
      }
    }

    /**
     * Backup Method for finding sentence, only use if sentence has no data available
     * 1. Step: Go left and find end of previous sentence or out of bounce => save index
     * 2. Step: Go right and find end of sentence or out of bounce => save index
     */
    if (Object.hasOwn(story, "textMapping")) {
      let start: number = 0;
      let end: number = 0;
      let sentence: WordResultMap[] = [];
      const exclude = ['Dr.', 'Prof.'];

      for (let index = wordIndex; index >= 0; index--) {
        const element = storyMap[index];
        if (element.word.includes(".") || element.word.includes("?") || element.word.includes("!") || index == 0) {
          if ((!exclude.includes(element.word) || index == 0) && index != wordIndex) {
            start = index;
            break;
          }
        }
      }

      for (let index = wordIndex; index < storyMap.length; index++) {
        const element = storyMap[index];
        if (element.word.includes(".") || element.word.includes("?") || element.word.includes("!") || index == storyMap.length - 1) {
          if (!exclude.includes(element.word) || index == 0) {
            end = index;
            break;
          }
        }
      }

      if (start != 0) start = start + 1;
      for (let index = start; index <= end; index++) {
        sentence.push(storyMap[index]);
      }

      return { sentence: sentence, position: wordIndex - start, totalPosition: wordIndex };
    } else {
      if (story && story.sentencesMeta) {
        for (let index = 0; index < story.sentencesMeta.length; index++) {
          const element = story.sentencesMeta[index];
          if (element.start <= wordIndex && element.end >= wordIndex) {
            let sentence: WordResultMap[] = [];
            for (let index2 = 0; index2 < story.mapping.length; index2++) {
              const wordEntry = story.mapping[index2];
              if (index2 >= element.start && index2 <= element.end) {
                sentence.push(wordEntry);
              }
            }

            return { sentence: sentence, position: wordIndex - element.start, totalPosition: wordIndex };
          }
        }
      }
    }
  }

  public getLevelCollection(language: string, level: string, skip = 0, limit = this.amountStoryLoading): Promise<any> {
    const fields = ["_id", "level", "schedule", "titles", "picture", "text", "storyId", "free"];
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/stories/app?levels=${level}&skip=${skip}&limit=${limit}&language=${language}&levelCollection=true&fields=${fields.join(",")}`, {
        headers: this.authService.authHeader
      }).subscribe((data) => {
        resolve(data);
      });
    });
  }

  resetHomeSlider() {
    this.showHomeSlider = false;
    timer(2000).subscribe(() => {
      this.showHomeSlider = true;
    });
  }

  updateHomeSlider() {
    this.eventUpdateHomeSlider.next(true);
  }

  recordOpenedStory(storyId: string, language: string): Promise<any> {
    return this.http.post<any>(`${environment.api}/user/openedStories/add`, {
      storyId: storyId,
      language: language
    }, {
      headers: this.authService.authHeader
    }).toPromise();
  }
}
