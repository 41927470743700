import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { LanguageService } from '@services/language-service/language.service';
import { TrainingService } from '@services/training-service/training.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'new-user-training-prompt',
  templateUrl: './new-user-training-prompt.component.html',
  styleUrls: ['./new-user-training-prompt.component.scss'],
})
export class NewUserTrainingComponent {
  environment = environment
  constructor(
    public languageService: LanguageService,
    public trainingService: TrainingService,
    public navCtrl: NavController
  ) { }

  confirm(): void {
    this.trainingService.enablePrompt = false;
    this.trainingService.newUserTrainingPrompt = false;
    this.navCtrl.navigateRoot('/training');
  }

  close(): void {
    this.trainingService.enablePrompt = false;
    this.trainingService.newUserTrainingPrompt = false;
  }

}
