import { Injectable } from '@angular/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _isTrackingAllowed = false;

  public getNameForLogin(username: string): string {
    return username;
  }

  public setIsTrackingAllowed(isTrackingAllowed: boolean): void {
    this._isTrackingAllowed = isTrackingAllowed;
  }

  public get isTrackingAllowed(): boolean {
    return this._isTrackingAllowed;
  }

  public async loadUserTrackingStatus(): Promise<void> {
    try {
      const res = await AppTrackingTransparency.getStatus();
      if (res.status === 'authorized') {
        this._isTrackingAllowed = true;
      } else {
        this._isTrackingAllowed = false
      }
    } catch (error) {
      console.error('Error loading user tracking status:', error);
    }
  }

}
