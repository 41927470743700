<div class="overlay"></div>
<div class="new-training-help-container">

  <div class="new-training-help-content">
    <h3>{{languageService.label.txt_training_prompt_heading}}</h3>

    <p>{{languageService.label.txt_training_prompt_description}}</p>

    <button class="confirm-btn decline" (click)="close()"
      [innerHTML]="languageService.label.txt_training_prompt_continue_story_cta"></button>
    <button class="confirm-btn" (click)="confirm()"
      [innerHTML]="languageService.label.txt_training_prompt_go_training_cta"></button>
  </div>

</div>