import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RateComponent } from './components/rate/rate/rate.component';
import { LoaderComponent } from './components/loader/loader.component';

import { environment } from '../environments/environment';
import { TrainingHelpComponent } from '@components/training-help/training-help.component';
import { StreakHelpComponent } from '@components/streak-help/streak-help.component';
import { NewTrainingComponent } from '@components/new-training-help/new-training-help.component';
import { NewUserTrainingComponent } from '@components/new-user-training-prompt/new-user-training-prompt.component';
import { DeckSettingsComponent } from '@components/deck-settings/deck-settings.component';
import { NoConnectionComponent } from '@components/no-connection/no-connection.component';
import { UpdatePromptComponent } from '@components/update-prompt/update-prompt.component';
import { FreeStoryInfoComponent } from '@components/free-story-info/free-story-info.component';
import { PipesModule } from '@pipes/pipes.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { FirebaseCrashlytics } from '@awesome-cordova-plugins/firebase-crashlytics/ngx';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { LoginPageModule } from '@pages/login/login.module';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import Bugsnag from '@bugsnag/js'
import { StripeModule } from "stripe-angular"
import { TransformCurrencyAmountPipe } from '@pipes/transform-currency-amount.pipe';
import { SQLiteService } from '@services/sqlite.service';
import { initilizeApp } from 'app-initializer';
//import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

Bugsnag.start('53a40bb221f4579681371f789bf8e5df')
//Bugsnag.notify(new Error('Something went wrong'))

// create a factory which will return the Bugsnag error handler
// export function errorHandlerFactory() {
//   return new BugsnagErrorHandler(client);
// }

@NgModule({
    declarations: [
        AppComponent,
        RateComponent,
        LoaderComponent,
        TrainingHelpComponent,
        StreakHelpComponent,
        NewTrainingComponent,
        NewUserTrainingComponent,
        DeckSettingsComponent,
        UpdatePromptComponent,
        FreeStoryInfoComponent,
        NoConnectionComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: environment.localDBName,
            driverOrder: [CordovaSQLiteDriver._driver, 'indexeddb', 'websql', 'localstorage'],
        }),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PipesModule,
        LoginPageModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300,
        }),
        StripeModule.forRoot(environment.stripe.key)
    ],
    exports: [],
    providers: [
        InAppBrowser,
        FirebaseCrashlytics,
        SQLiteService,
        {
            provide: APP_INITIALIZER,
            useFactory: initilizeApp,
            deps: [SQLiteService],
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: 'googleTagManagerId', useValue: environment.gtm.id },
        LaunchReview,
        GooglePlus,
        Deeplinks,
        SignInWithApple,
        TransformCurrencyAmountPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
