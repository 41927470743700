import { Injectable } from '@angular/core';
import { StoryService } from '../story-service/story.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language-service/language.service';
import { AuthService } from '@services/auth-service/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TrainingService } from '@services/training-service/training.service';
import { Platform } from '@ionic/angular';
import { UtilsService } from '@services/utils-service/utils.service';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { SQLiteService } from '@services/sqlite.service';
import { getFavoriteStoriesKey, getLearnedStoriesFilterStateKey, getLearnedStoriesKey, StorageKeys } from '@interfaces/storage.keys.interface';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public userLevel: string;
  public sampleMode = false;
  public favorites: string[] = [];
  public learnedStories: string[] = [];
  public learnedStoriesChanged: Subject<any> = new Subject<any>();
  public unlockedStoryByReviewChanges: Subject<any> = new Subject<any>();
  public unlockedStoryByShareChanges: Subject<any> = new Subject<any>();
  public allLearnedStoriesChanged: Subject<any> = new Subject<any>();
  public unlockedStoryByFreeChanges: Subject<any> = new Subject<any>();

  private unlockedStoryByReview: string | null | undefined = undefined
  private unlockedStoryByShare: string | null | undefined = undefined;
  private unlockedStoryByFree: string | null | undefined = undefined;
  private isFirstSessionValue: boolean | null ;

  private isFirstSessionDisabled = false;
  public importLoading = false;

  favLoading = false;
  learnedLoading = false;

  constructor(
    private _sqliteService: SQLiteService,
    private platform: Platform,
    private utilsService: UtilsService,
    private articleService: StoryService,
    private languageService: LanguageService,
    private authService: AuthService,
    private http: HttpClient,
    private trainingService: TrainingService,
  ) {
    this.languageService.watchOrigin.subscribe(origin => {
      this.loadFavorites(origin);
      this.loadLearnedStories(origin);
    });
  }

  public initUserSettings(): void {
    this.loadUserLevelFromStorage();

    this._sqliteService.getItem(StorageKeys.SETTING_HIGHLIGHT).then((val: boolean) => {
      if (val == null)
        this._sqliteService.setItem(StorageKeys.SETTING_HIGHLIGHT, true);
    });

    this._sqliteService.getItem(StorageKeys.SETTING_NOTIFICATIONS).then((val: boolean) => {
      if (val == null)
        this._sqliteService.setItem(StorageKeys.SETTING_NOTIFICATIONS, true);
    });
  }

  public loadUserLevelFromStorage(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(StorageKeys.USER_LEVEL).then((val: string) => {
        this.userLevel = val;
        resolve(this.userLevel)
      });
    })
  }

  public storeUserLevel(level: string, updateRemoteData = true): void {
    this.userLevel = level;
    this._sqliteService.setItem(StorageKeys.USER_LEVEL, level);
    if (!environment.single) {
      switch (this.languageService.origin) {
        case 'fr':
          this._sqliteService.setItem(StorageKeys.FRENCH_USER_LEVEL, level);
          break;
        case 'de':
          this._sqliteService.setItem(StorageKeys.GERMAN_USER_LEVEL, level);
          break;
        case 'en':
          this._sqliteService.setItem(StorageKeys.ENGLISH_USER_LEVEL, level);
          break;
        case 'es':
          this._sqliteService.setItem(StorageKeys.SPANISH_USER_LEVEL, level);
          break;
        case 'ja':
          this._sqliteService.setItem(StorageKeys.JAPANESE_USER_LEVEL, level);
          break;
        case 'zh-hans':
          this._sqliteService.setItem(StorageKeys.CHINESE_USER_LEVEL, level);
          break;
      }
    }
    this.sendRevenueCatAttributes();
    if (updateRemoteData) {
      this.authService.storeUserData({ selectedLevel: level });
    }
  }

  public checkFavorite(id: string): boolean {
    return this.favorites.includes(id);
  }

  public addFavorite(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesFavorite/add`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    this.favorites.push(id);
    this.saveFavorites();
  }

  public removeFavorite(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesFavorite/remove`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    const index = this.favorites.indexOf(id);
    if (index > -1) this.favorites.splice(index, 1);
    for (let index = 0; index < this.articleService.favoritesArticles.length; index++) {
      const element = this.articleService.favoritesArticles[index];
      if (element._id == id) {
        this.articleService.favoritesArticles.splice(index, 1);
      }
    }

    this.saveFavorites();
  }

  public saveFavorites(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._sqliteService.setItem(getFavoriteStoriesKey(this.languageService.origin), JSON.stringify(this.favorites)).then((res) => {
        resolve(res);
      });
    })

  }

  public loadFavorites(languageCode: string): Promise<any> {
    if (this.favLoading) return;
    this.favorites = [];
    this.favLoading = true;
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(getFavoriteStoriesKey(this.languageService.origin)).then(async (data: string) => {
        if (data) {
          this.favorites = JSON.parse(data);
          await this.articleService.getFavorites(this.favorites, languageCode).then((res) => {
            this.favLoading = false;
          });
        } else {
          this.favorites = [];
          this.articleService.favoritesArticles = [];
        }
        resolve(data);
      });
    });
  }

  public addLearnedStory(id: string): void {

    console.log("DEBUG_" + id)

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesLearned/add`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    this.learnedStories.push(id);
    this.learnedStoriesChanged.next({ id: id, added: true });
    this.saveLearnedStories();
  }

  public removeLearnedStory(id: string): void {

    if (this.authService.token) {
      this.http.post(`${environment.api}/user/storiesLearned/remove`, { storyId: id, language: this.languageService.origin }, { headers: this.authService.authHeader }).subscribe((res) => { });
    }

    const index = this.learnedStories.indexOf(id);
    if (index > -1) this.learnedStories.splice(index, 1);
    this.learnedStoriesChanged.next({ id: id, added: false });
    for (let index = 0; index < this.articleService.LearnedArticles.length; index++) {
      const element = this.articleService.LearnedArticles[index];
      if (element._id == id) {
        this.articleService.LearnedArticles.splice(index, 1);
      }
    }
    this.saveLearnedStories();
  }

  public saveLearnedStories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._sqliteService.setItem(getLearnedStoriesKey(this.languageService.origin), JSON.stringify(this.learnedStories)).then((res) => {
        resolve(res);
      })
    });
  }

  public loadLearnedStories(languageCode: string): Promise<any> {
    if (this.learnedLoading) return;
    this.learnedStories = [];
    this.learnedLoading = true;
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(getLearnedStoriesKey(this.languageService.origin)).then((data: string) => {
        this.learnedLoading = false;
        if (data) {
          this.learnedStories = JSON.parse(data);
          this.articleService.getLearned(this.learnedStories, languageCode).then((res) => {
            this.learnedLoading = false;
          });;
        } else {
          this.learnedStories = [];
          this.articleService.LearnedArticles = [];
        }
        resolve(data)
      });
    });
  }

  public getLearnedStories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(getLearnedStoriesKey(this.languageService.origin)).then((data: string) => {
        resolve(JSON.parse(data));
      });
    })
  }

  public saveLearnedStoriesFilterState(state: boolean): Promise<any> {
    return this._sqliteService.setItem(getLearnedStoriesFilterStateKey(this.languageService.origin), state);
  }

  public loadLearnedStoriesFilterState(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const val = await this._sqliteService.getItem(getLearnedStoriesFilterStateKey(this.languageService.origin)) as boolean;
      if (val) resolve(true);
      else resolve(false);
    })
  }

  public transferFromLocalStorage(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const dbName = '_ionicstorage';
      // Check if the user has data in the local storage
      // (Still an implementation from transfering data from our old app to the new one, before SQLite change)
      // If there is no userid in the local storage, then there is no data to transfer
      // We can skip the transfer process and increase the performance here
      const check = localStorage.getItem(dbName + '/_ionickv/' + StorageKeys.USER_ID);
      if (!check) {
        return resolve(false);
      }

      await this.transferToStorage(dbName, StorageKeys.SETTING_HIGHLIGHT);
      await this.transferToStorage(dbName, StorageKeys.SETTING_NOTIFICATIONS);
      await this.transferToStorage(dbName, StorageKeys.USER_LEVEL);

      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("en"));
      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("fr"));
      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("es"));
      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("de"));
      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("ja"));
      await this.transferToStorage(dbName, getLearnedStoriesFilterStateKey("zh-hans"));

      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_EN);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_FR);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_ES);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_DE);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_JA);
      await this.transferToStorage(dbName, StorageKeys.TRAINING_STACK_ZH_HANS);

      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_EN);
      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_FR);
      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_ES);
      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_DE);
      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_JA);
      await this.transferToStorage(dbName, StorageKeys.LEARNED_STORIES_ZH_HANS);

      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_EN);
      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_DE);
      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_FR);
      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_ES);
      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_JA);
      await this.transferToStorage(dbName, StorageKeys.FAVORITE_STORIES_ZH_HANS);

      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_EN);
      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_DE);
      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_FR);
      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_ES);
      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_JA);
      await this.transferToStorage(dbName, StorageKeys.OPENED_STORIES_ZH_HANS);

      await this.transferToStorage(dbName, StorageKeys.READLE_APPEARANCE);
      await this.transferToStorage(dbName, StorageKeys.USER_ID);
      await this.transferToStorage(dbName, StorageKeys.ORIGIN_LANGUAGE);
      await this.transferToStorage(dbName, StorageKeys.TRANSLATIONS_LANGUAGE);
      await this.transferToStorage(dbName, StorageKeys.USER_DID_OPEN_ARTICLE_BEFORE);

      resolve(true);
    })
  }

  public emptyLocalStorage() {
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_EN);
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_DE);
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_FR);
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_ES);
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_JA);
    this._sqliteService.removeItem(StorageKeys.FAVORITE_STORIES_ZH_HANS);

    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_EN);
    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_FR);
    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_ES);
    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_DE);
    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_JA);
    this._sqliteService.removeItem(StorageKeys.LEARNED_STORIES_ZH_HANS);

    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_EN);
    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_FR);
    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_DE);
    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_ES);
    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_JA);
    this._sqliteService.removeItem(StorageKeys.OPENED_STORIES_ZH_HANS);

    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_EN);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_FR);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_ES);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_DE);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_JA);
    this._sqliteService.removeItem(StorageKeys.TRAINING_STACK_ZH_HANS);
  }

  private async transferToStorage(dbName: string, storageName: StorageKeys) {
    return new Promise(async (resolve, reject) => {
      let localreadleApperance = localStorage.getItem(dbName + '/_ionickv/' + storageName);
      let storagereadleApperance = await this._sqliteService.getItem(storageName) as string;

      if (!storagereadleApperance && localreadleApperance) {
        await this._sqliteService.setItem(storageName, JSON.parse(localreadleApperance));
      }
      resolve(true);
    })
  }

  public setUnlockedStoryByReview(id: string) {
    this._sqliteService.setItem(StorageKeys.UNLOCKED_STORY_BY_REVIEW, id);
    this.unlockedStoryByReview = id;
    this.unlockedStoryByReviewChanges.next(id);
  }

  public setUnlockedStoryByShare(id: string) {
    this._sqliteService.setItem(StorageKeys.UNLOCKED_STORY_BY_SHARE, id);
    this.unlockedStoryByShare = id;
    this.unlockedStoryByShareChanges.next(id);
  }

  public setUnlockedStoryByFree(id: string) {
    this._sqliteService.setItem(StorageKeys.UNLOCKED_STORY_BY_FREE, id);
    this.unlockedStoryByFree = id;
    this.unlockedStoryByFreeChanges.next(id);
  }

  public async getUnlockedStoryByReview(): Promise<string | null> {
    if (this.unlockedStoryByReview != undefined || this.unlockedStoryByReview == null) {
      return this.unlockedStoryByReview;
    }
    try {
      const res = await this._sqliteService.getItem(StorageKeys.UNLOCKED_STORY_BY_REVIEW);
      this.unlockedStoryByReview = res;
      return res || null;
    } catch (err) {
      return null;
    }
  }

  public async getUnlockedStoryByFree(): Promise<string | null> {
    if (this.unlockedStoryByFree != undefined || this.unlockedStoryByFree == null) {
      return this.unlockedStoryByFree;
    } else {
      try {
        const res = await this._sqliteService.getItem(StorageKeys.UNLOCKED_STORY_BY_FREE);
        this.unlockedStoryByFree = res;
        return res || null;
      } catch (err) {
        return null;
      }
    }
  }

  public async getUnlockedStoryByShare(): Promise<string | null> {
    if (this.unlockedStoryByShare != undefined || this.unlockedStoryByShare == null) {
      return this.unlockedStoryByShare;
    } else {
      try {
        const res = await this._sqliteService.getItem(StorageKeys.UNLOCKED_STORY_BY_SHARE);
        this.unlockedStoryByShare = res;
        return res || null;
      } catch (err) {
        return null;
      }
    }
  }

  public async isFirstSession(): Promise<boolean> {
    if (this.isFirstSessionValue != null) {
      return this.isFirstSessionValue;
    } else {
      let value = await this._sqliteService.getItem(StorageKeys.APP_STARTS);
      let firstSession = false;
      if (!value || value == null || value == 1) firstSession = true;
      if (this.isFirstSessionDisabled) return false;
      this.isFirstSessionValue = firstSession;
      return firstSession;
    }
  }

  public disableFirstSession() {
    this.isFirstSessionDisabled = true;
  }

  public async sendRevenueCatAttributes() {
    let device_language = this.languageService.originalDeviceLanguage;
    let store_locale = "";

    if (device_language.includes('-')) {
      if (device_language.includes('zh-Han')) {//ios chinese
        const lang = device_language.split("-");
        device_language = lang[0] + "-" + lang[1];
        store_locale = lang[2];
        if (store_locale) store_locale = store_locale.toLowerCase();
      } else if (device_language.includes('zh')) {//android chinese
        device_language = await this.languageService.checkAndroid(this.languageService.originalDeviceLanguage);
        const lang = device_language.split("-");
        device_language = lang[0] + "-" + lang[1];
        store_locale = "";
      } else {//other languages
        const lang = device_language.split("-");
        device_language = lang[0];
        store_locale = lang[1];
        if (store_locale && !this.platform.is('android')) store_locale = store_locale.toLowerCase();
        else if (this.platform.is('android') && !device_language.includes('zh')) store_locale = store_locale.toLowerCase();
        else store_locale = "";
      }
    }

    const data = {
      level: this.userLevel || '',
      learning_language: this.languageService.origin || '',
      device_language: device_language || '',
      store_locale: store_locale || '',
      $mparticleId: device_language || '',
      $mixpanelDistinctId: this.languageService.origin || ''
    }

    setTimeout(() => {
      Purchases.setAttributes(data);
    }, 7000);

    /**
     * Send Attributes to RevenueCat via the API
     * This is only for the web version
     */
    if (this.utilsService.isWebVersion) {
      const url = 'https://api.revenuecat.com/v1/subscribers/' + await this.authService.getUserID() + '/attributes';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer' + environment.revenueCat.stripeAPIKey
        },
        body: JSON.stringify({
          attributes: { 
            level: { value: this.userLevel },
            learning_language: { "value": this.languageService.origin },
            device_language: { value: navigator.language.toLowerCase() },
            $mparticleId: { value: navigator.language.toLowerCase() },
            $mixpanelDistinctId: { "value": this.languageService.origin }
          }
        })
      };

      fetch(url, options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    }
  }


  public async storeInitUserDataOnServer(): Promise<any> {
    console.log("storeInitUserDataOnServer")
    return new Promise(async (resolve, reject) => {

      if (!this.authService.token) {
        console.log("No token available for storeInitUserDataOnServer")
        return reject("");
      }

      const storiesLearned = [];
      const storiesFavorite = [];
      const openedStories = [];

      let x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_EN) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_FR) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_DE) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_ES) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_JA) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      x = await this._sqliteService.getItem(StorageKeys.FAVORITE_STORIES_ZH_HANS) as string;
      if (x) storiesFavorite.push.apply(storiesFavorite, JSON.parse(x));

      //
      let y;
      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_DE) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_EN) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_FR) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_ES) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_JA) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      y = await this._sqliteService.getItem(StorageKeys.LEARNED_STORIES_ZH_HANS) as string;
      if (y) storiesLearned.push.apply(storiesLearned, JSON.parse(y));

      let z;
      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_DE) as string;
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_EN) as string;
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_FR) as string;
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_ES) as string;

      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_JA) as string;
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      z = await this._sqliteService.getItem(StorageKeys.OPENED_STORIES_ZH_HANS) as string;
      if (z) storiesLearned.push.apply(openedStories, JSON.parse(z));

      this.http.post(`${environment.api}/user/data/add`, {
        favoriteStories: storiesFavorite,
        learnedStories: storiesLearned,
        openedStories: openedStories,
        openedStoriesLanguage: this.languageService.origin,
        training: [],
      }, { headers: this.authService.authHeader }).subscribe(async (data) => {
        return resolve(data);
      }, (err) => {
        return reject(err);
      })

    });
  }

  public async importTrainingDataToServer(): Promise<any> {
    console.log("importTrainingDataToServer start");
    if (this.authService.user.trainingMigrationStart) return;

    return new Promise(async (resolve, reject) => {
      this.importLoading = true;
      const training = await this.getConvertedTrainingData();

      this.http.post(`${environment.api}/user/data/add`, {
        favoriteStories: [],
        learnedStories: [],
        openedStories: [],
        training: training,
      }, { headers: this.authService.authHeader }).subscribe((data) => {
        console.log("importTrainingDataToServer completed")
        if (!this.authService.user.trainingMigrationStart || this.authService.user.trainingMigrationStart == null) {
          console.log('migration in storeInitUserDataOnServer', JSON.stringify(training));
          setTimeout(async () => {
            await this.trainingService.migrateTrainingData();
          }, 500);
        }
        this.importLoading = false;
        return resolve(data);
      }, (err) => {
        return reject(err);
      })
    })
  }

  public async getConvertedTrainingData() {
    // construct training data
    let z: string;
    let training = [];
    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_DE) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'de';
        training.push(element);
      }
    }

    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_EN) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'en';
        training.push(element);
      }
    }

    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_FR) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'fr';
        training.push(element);
      }
    }

    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_ES) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'es';
        training.push(element);
      }
    }

    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_JA) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'ja';
        training.push(element);
      }
    }

    z = await this._sqliteService.getItem(StorageKeys.TRAINING_STACK_ZH_HANS) as string;
    if (z) {
      const x = JSON.parse(z).words;
      for (let i = 0; i < x.length; i++) {
        let element = x[i];
        element = this.trainingService.prepareTrainingWordForServer(element);
        element['language'] = 'zh-hans';
        training.push(element);
      }
    }

    return training;
  }

  public async initUserDataFromServer() {
    if (!this.authService.user || !this.authService.user.data || this.authService.user.data.length == 0) return;
    console.log('initUserDataFromServer')

    const favs = this.authService.user?.data?.find((el => el.language === this.languageService.origin))?.storiesFavorite;
    const learned = this.authService.user?.data?.find((el => el.language === this.languageService.origin))?.storiesLearned;

    // Init favorites
    if (favs && favs.length > 0) {
      console.log('initUserDataFromServer favs', favs.length)
      this.favorites = favs; // set favorites to server data
      await this.saveFavorites(); // save favorites to local storage
      // this.loadFavorites(this.authService.user.selectedLanguage); // load new favorites from server
    } else {
      this.favorites = [];
      await this.saveFavorites(); // save favorites to local storage
    }

    // Init learned stories
    if (learned && learned.length > 0) {
      console.log('initUserDataFromServer favs', learned.length)

      this.learnedStories = learned; // set favorites to server data
      await this.saveLearnedStories(); // save favorites to local storage
      // this.loadLearnedStories(this.authService.user.selectedLanguage); // load new favorites from server
      this.allLearnedStoriesChanged.next(this.learnedStories);
    } else {
      this.learnedStories = [];
      await this.saveLearnedStories(); // save favorites to local storage
    }

  }

  public removeUserDataAfterLogout() {
    this.favorites = [];
    this.learnedStories = [];
    this.trainingService.resetStack();

    this.saveFavorites();
    this.saveLearnedStories();
  }

  public useLocalData() {
    console.log("useLocalData loadFavorites")
    this.loadFavorites(this.languageService.origin);
    this.loadLearnedStories(this.languageService.origin);
  }

  public async importReadleData(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this._sqliteService.setItem(StorageKeys.IS_READLE_DATA_IMPORTED, true);
      await this.importSingleKey('userLevel', StorageKeys.USER_LEVEL);
      await this.importSingleKey('favoriteArticles', StorageKeys.FAVORITE_STORIES_DE);
      await this.importSingleKey('ReadleLearnedStoriesFilterState', StorageKeys.LEARNED_STORIES_FILTER_STATE_DE);
      await this.importSingleKey('TRAINING_STACK', StorageKeys.TRAINING_STACK_DE);
      await this.importSingleKey('readle-user-id', StorageKeys.USER_ID);
      await this._sqliteService.setItem(StorageKeys.ORIGIN_LANGUAGE, 'de');
      await this.importSingleKey('userRated', StorageKeys.USER_RATED);

      // appStarts seperate
      let tmp = await this._sqliteService.getItem(StorageKeys.APP_STARTS) as number;
      if (tmp == 1) tmp = 3;
      if (tmp) await this._sqliteService.setItem(StorageKeys.APP_STARTS, tmp);

      const learnedStories = await localStorage.getItem('learnedStories') as string;
      this.http.post(`${environment.api}/stories/app/convertStoryIds`, {
        language: 'de',
        storyIds: JSON.parse(learnedStories)
      }, { headers: this.authService.authHeader }).subscribe((result: any) => {
        if (result) {
          this._sqliteService.setItem(StorageKeys.LEARNED_STORIES_DE, JSON.stringify(result));
          this.learnedStories = result as string[];
          resolve();
        }
      }, (error: any) => {
        resolve();
      });
    });
  }

  private async importSingleKey(readleKey: string, langsterKey: StorageKeys) {
    const tmp = await localStorage.getItem(readleKey) as string;
    if (tmp) await this._sqliteService.setItem(langsterKey, tmp);
  }

  public async getIsReadleDataImported(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const tmp: boolean = await this._sqliteService.getItem(StorageKeys.IS_READLE_DATA_IMPORTED) as boolean;
      if (tmp) resolve(true);
      else resolve(false);
    });
  }

}
