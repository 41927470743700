import { SQLiteService } from "@services/sqlite.service";

export function initilizeApp(sqliteService: SQLiteService) {
    return async () => {
        try {
            await sqliteService.initializePlugin();
            await sqliteService.migrateIndexedDBToSQLite();
          } catch (error) {
            console.error("SQLite initialization/migration error", JSON.stringify(error));
          }
    }
}