export enum PurchaseOrigin {
    LIBRARYWELCOME = 'libraryWelcome',
    NAVIGATION = 'navigation',
    FREESTORYINFO = 'FREESTORYINFO',
    READMORE = 'readmore',
    POPUP = 'popup',
    ONBOARDINGMODAL = "onboardingModal",
    OTHER = 'other',
    SETTINGS = 'settings',
    ALLPLANS = 'allPlans',
    PROMO = 'promo'
}