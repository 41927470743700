import { Pipe, PipeTransform } from '@angular/core';
import { WordResultMap } from '@interfaces/story';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
    name: 'furigana'
})
export class WordFuriganaPipe implements PipeTransform {

    constructor(
        private languageService: LanguageService,
    ) {}

    transform(word: WordResultMap): string {
        if (/[\u4E00-\u9FFF]/.test(word.word)) {
            if (word.result?.hiragana && word.word[0] == word.result?.hiragana[0] && !(word.result.category == 'verb' || word.result.category == 'adjective')) {
                return this.removeCommonCharacters(word.word, word.result.hiragana) || '';
            }
            const result = this.getFuriganaWord(word.result, word.word)
            if (word.result?.hiragana && word.word[0] == word.result?.hiragana[0]) {
                const charSet = new Set(result);
                const check = word.word.split('').filter(char => !charSet.has(char)).join('');
                if (check) console.log('check', word.word, word.result?.hiragana, check);
            }
            return result || '';
        } else {
            return '';
        }
    }

    public getFuriganaWord(word: Word, displayWord: string = ''): string {
        if (!word.hiragana || this.languageService.origin != 'ja') return '';
        if (word.category == 'verb' || word.category == 'adjective') {
          let Kanji, pronounciation;
          if (displayWord) Kanji = displayWord
          else Kanji = word.display
          Kanji = Kanji.replace(/[。、！？]/g, ''); // Remove Japanese punctuation
          pronounciation = word.hiragana;
          for (let index = 0; index < word.forms.length; index++) {
            if (word.forms[index].kanji == Kanji) {
              pronounciation = word.forms[index].hiragana;
              break;
            }
          }
          const furigana = this.compareAndModifyTextDynamic(pronounciation, Kanji)
          return furigana || '';
        } else {
          const furigana = this.compareAndModifyTextDynamic(word.hiragana, word.display)
          return furigana;
        }
      }

    private removeCommonCharacters(str1: string, str2: string): string {
        // Create a Set of characters from the first string for quick lookup
        const charSet = new Set(str1);
        // Filter out characters from the second string that are present in the first string
        const result = str2.split('').filter(char => !charSet.has(char)).join('');
        const spaceCharacters = this.countBeforeDifferentCharacter(str1, str2);
        const r = document.querySelector(':root') as HTMLElement;
        r.style.setProperty('--furigana-space', spaceCharacters.toString());
        return result;
    }

    private countBeforeDifferentCharacter(str1: string, str2: string): number {
        for (let i = 0; i < str2.length; i++) {
            if (str1[i] !== str2[i]) {
                return i;
            }
        }
        return -1; // Return -1 if no different character is found
    }

    // Function to compare two strings, find the common substring, and remove the excess part from the first string
  private compareAndModifyTextDynamic(text1, text2) {
    // Find the common substring
    const commonSubstring = this.findCommonSubstring(text1, text2);
    // If there's a common part, remove it from the end of the kanji and return the result
    if (commonSubstring) {
      return text1.slice(0, -commonSubstring.length);
    }
    return text1;
  }

  private findCommonSubstring(text1, text2) {
    let commonSubstring = '';
    // Compare both strings character by character
    let len1 = text1.length - 1;
    let len2 = text2.length - 1;
    while (len1 >= 0 && len2 >= 0 && text1[len1] === text2[len2]) {
      commonSubstring = text1[len1] + commonSubstring;
      len1--;
      len2--;
    }
    return commonSubstring;
  }

}
