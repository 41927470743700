import { PluginListenerHandle, registerPlugin, WebPlugin } from '@capacitor/core';

export interface AudioPlayerPlugin {
  play(options: { url: string }): Promise<void>;
  pause(): Promise<void>;
  stop(): Promise<void>;
  addListener(eventName: 'audioProgress', listenerFunc: (data: { currentTime: number }) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
}

class AudioPlayerWeb extends WebPlugin implements AudioPlayerPlugin {
  constructor() {
    super({
      name: 'AudioPlayer',
      platforms: ['web'],
    });
  }

  async play(options: { url: string }): Promise<void> {
    console.log('Playing audio from URL:', options.url);
  }

  async pause(): Promise<void> {
    console.log('Pausing audio');
  }

  async stop(): Promise<void> {
    console.log('Stopping audio');
  }

  addListener(eventName: 'audioProgress', listenerFunc: (data: { currentTime: number }) => void): Promise<PluginListenerHandle> & PluginListenerHandle {
    console.log('Adding listener for event:', eventName);
    console.log('Calling listener function:', listenerFunc);

    const handle: PluginListenerHandle = {
      remove: async () => {
        console.log('Removing listener for event:', eventName);
      }
    };

    // Implementierung hier
    return Object.assign(Promise.resolve(handle), handle);
  }
}

const AudioPlayer = registerPlugin<AudioPlayerPlugin>('AudioPlayerPlugin', { web: new AudioPlayerWeb() });

export { AudioPlayer };
