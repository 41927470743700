import { Injectable } from '@angular/core';
import { Globalization } from '@awesome-cordova-plugins/globalization';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { StoryService } from '../story-service/story.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '@services/auth-service/auth.service';
import { Subject } from 'rxjs';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';

const DEFAULT_LANGUAGE_CODE = 'eng';
/**
 * Available language-codes:
 *
 * zh-Hans (Simplified chinese)
 * zh-Hant (traditional chinese)
 * ja (japanese)
 * fr (french)
 * tr (turkish)
 * eng (english default)
 *
 *
 *
 * Important step after getting the users language is to call the following two methods with a language-code as a parameter:
 * this.getLabelData("eng"); => Loads the related Labels for that language
 * this.setLanguage("eng"); => Sets the language for content in the app
 *
 */

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public origin = '';
  public translations = '';
  public target = '';
  public watchOrigin: Subject<string> = new Subject<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public label: any = {};
  public originalDeviceLanguage = '';

  constructor(
    //private api: APIService,
    private platform: Platform,
    private http: HttpClient,
    private storyService: StoryService,
    private authService: AuthService,
    private _sqliteService: SQLiteService,
    private onboardingService: OnboardingService
  ) { }

  /**
   *
   * @memberof LanguageService
   * @description
   * Checking the default language of the device
   * and initializing query for labels
   */
  public checkLanguage(): Promise<string> {
    return new Promise(async (resolve) => {
      let origin;
      if (environment.single && environment.language) {
        origin = environment.language;
      } else {
        origin = await this._sqliteService.getItem(StorageKeys.ORIGIN_LANGUAGE) as string;
      }

      const storedTarget = await this.loadTargetLanguage();
      if (this.translations == 'eng') this.translations = 'en'

      if (origin) this.origin = origin;
      this.watchOrigin.next(this.origin);

      Globalization.getPreferredLanguage().then(async (res) => {
        let languageCode = DEFAULT_LANGUAGE_CODE;

        if (res.value) this.originalDeviceLanguage = res.value;

        if (!storedTarget) {
          if (this.platform.is('ios')) languageCode = await this.checkIos(res.value);
          if (this.platform.is('android')) languageCode = await this.checkAndroid(res.value);
        } else {
          languageCode = storedTarget;
        }

        // SET DEFAULT LANGUAGE FOR SLA WHEN EQUAL ORIGIN AND TARGET (EN WILL BE EN ANYWAY)
        if (environment.single && environment.language == 'fr' && languageCode == 'fr') languageCode = 'eng';
        if (environment.single && environment.language == 'de' && languageCode == 'de') languageCode = 'eng';
        if (environment.single && environment.language == 'es' && languageCode == 'es') languageCode = 'eng';
        if (environment.single && environment.language == 'ja' && languageCode == 'ja') languageCode = 'eng';


        this.setUserTargetLanguage(languageCode);
        this.getLabelData(languageCode);
        return resolve(languageCode);
      }).catch((e) => { });

      // Workaround for label-support in Browser
      if (!this.platform.is('cordova')) {
        let browserLanguage = navigator.language;
        let l = await this.checkAndroid(browserLanguage);
        if (storedTarget) { l = storedTarget; }

        // SET DEFAULT LANGUAGE FOR SLA WHEN EQUAL ORIGIN AND TARGET (EN WILL BE EN ANYWAY)
        if (environment.single && environment.language == 'fr' && l == 'fr') l = 'eng';
        if (environment.single && environment.language == 'de' && l == 'de') l = 'eng';
        if (environment.single && environment.language == 'es' && l == 'es') l = 'eng';
        if (environment.single && environment.language == 'ja' && l == 'ja') l = 'eng';

        this.getLabelData(l);
        this.setUserTargetLanguage(l);
        return resolve(l);
      }
    });
  }

  private checkIos(detectedLanguage: string): Promise<string> {
    return new Promise((resolve) => {
      let languageCode = DEFAULT_LANGUAGE_CODE;
      const lang = detectedLanguage;
      const arr = lang.split('-');
      const userLang = arr[0] + '-' + arr[1];

      if (arr[0] == 'ja' || arr[0] == 'fr' || arr[0] == 'tr' || arr[0] == 'de' || arr[0] == 'ko' || arr[0] == 'es' || arr[0] == 'uk') {
        switch (arr[0]) {
          case 'ja':
            languageCode = 'ja';
            break;
          case 'fr':
            languageCode = 'fr';
            break;
          case 'tr':
            languageCode = 'tr';
            break;
          case 'es':
            languageCode = 'es';
            break;
          case 'uk':
            languageCode = 'uk';
            break;
          case 'ko':
            languageCode = 'ko';
            break;
          case 'de':
            languageCode = 'de';
            break;
        }
      } else {
        switch (userLang) {
          case 'zh-Hans':
          case 'zh-CN':
          case 'zh-SG':
          case 'zh':
            languageCode = 'zh-Hans';
            break;
          case 'zh-Hant':
          case 'zh-TW':
          case 'zh-HK':
          case 'zh-MO':
            languageCode = 'zh-Hant';
            break;
          case 'ja':
            languageCode = 'ja';
            break;
          case 'fr':
            languageCode = 'fr';
            break;
          case 'ko':
            languageCode = 'ko';
            break;
          case 'de':
            languageCode = 'de';
            break;
          case 'es':
            languageCode = 'es';
            break;
          case 'uk':
            languageCode = 'uk';
            break;
          default:
            languageCode = 'eng';
            break;
        }
        if (arr[0] == 'it' || arr[0] == 'pt' || arr[0] == 'pl' || arr[0] == 'ru' || arr[0] == 'nl' || arr[0] == 'vi' || arr[0] == 'id' || arr[0] == 'hu' || arr[0] == 'cs' || arr[0] == 'ar' || arr[0] == 'no' || arr[0] == 'nn' || arr[0] == 'nb' || arr[0] == 'fi' || arr[0] == 'sv') {
          languageCode = arr[0];
          if (arr[0] == 'id') {
            languageCode = 'in';
          } else if (arr[0] == 'nn' || arr[0] == 'nb') {
            languageCode = 'no';
          }
          // this.storage.set(STORAGE_KEY_TRANSLATIONS_LANGUAGE, languageCode);
        }
      }
      resolve(languageCode);
    })
  }

  public checkAndroid(detectedLanguage: string): Promise<string> {

    return new Promise((resolve) => {

      let languageCode = DEFAULT_LANGUAGE_CODE;
      const lang = detectedLanguage;
      const arr = lang.split('-');

      if (arr[0] == 'ja') {
        languageCode = 'ja';
      } else if (arr[0] == 'fr') {
        languageCode = 'fr';
      } else if (arr[0] == 'tr') {
        languageCode = 'tr';
      } else if (arr[0] == 'ko') {
        languageCode = 'ko';
      } else if (arr[0] == 'de') {
        languageCode = 'de';
      } else if (arr[0] == 'uk') {
        languageCode = 'uk';
      } else if (arr[0] == 'es') {
        languageCode = 'es';
      } else {
        switch (lang) {
          case 'zh':
            languageCode = 'zh-Hans';
            break;
          case 'zh-CN':
            languageCode = 'zh-Hans';
            break;
          case 'zh-SG':
            languageCode = 'zh-Hans';
            break;
          case 'zh-HK':
            languageCode = 'zh-Hant';
            break;
          case 'zh-MO':
            languageCode = 'zh-Hans';
            break;
          case 'zh-TW':
            languageCode = 'zh-Hant';
            break;
          case 'ja-JA':
            languageCode = 'ja';
            break;
          case 'fr-FR':
            languageCode = 'fr';
            break;
          case 'uk':
            languageCode = 'uk';
            break;
          case 'ko':
            languageCode = 'ko';
            break;
          case 'es':
            languageCode = 'es';
            break;
          default:
            languageCode = 'eng';
            break;
        }
        if (arr[0] == 'it' || arr[0] == 'pt' || arr[0] == 'pl' || arr[0] == 'ru' || arr[0] == 'nl' || arr[0] == 'vi' || arr[0] == 'id' || arr[0] == 'hu' || arr[0] == 'cs' || arr[0] == 'ar' || arr[0] == 'no' || arr[0] == 'nn' || arr[0] == 'nb' || arr[0] == 'fi' || arr[0] == 'sv') {
          languageCode = arr[0];
          if (arr[0] == 'id') {
            languageCode = 'in';
          } else if (arr[0] == 'nn' || arr[0] == 'nb') {
            languageCode = 'no';
          }
        }
      }
      resolve(languageCode);
    });
  }

  // private checkWeb(detectedLanguage: string):string {
  //   let languageCode = DEFAULT_LANGUAGE_CODE;
  //     const lang = detectedLanguage;
  // }

  public async setUserTargetLanguage(code: string): Promise<void> {
    // SET DEFAULT LANGUAGE WHEN EQUAL ORIGIN AND TARGET
    if (this.origin == 'fr' && code == 'fr') code = 'eng';
    if (this.origin == 'de' && code == 'de') code = 'eng';
    if (this.origin == 'es' && code == 'es') code = 'eng';
    if (this.origin == 'ja' && code == 'ja') code = 'eng';
    if (this.origin == 'zh-hans' && code == 'zh-hans') code = 'eng';


    this.target = code.toLocaleLowerCase();
    await this._sqliteService.setItem(StorageKeys.TARGET_LANGUAGE, code);
    this.authService.storeUserData({ userLanguage: code });
  }

  public async setOrigin(code: string, updateRemoteData = true): Promise<void> {
    return new Promise(async (resolve) => {
      try {
        this.origin = code;
        if (environment.single && environment.language) {
          this.origin = environment.language;
        }
        this.storyService.showHomeSlider = false;
        if (!this.onboardingService.isActive) await this.storyService.initAppWithStories(this.origin);
        this.storyService.showHomeSlider = true;
        await this._sqliteService.setItem(StorageKeys.ORIGIN_LANGUAGE, this.origin);
        await this.authService.storeUserData({ selectedLanguage: this.origin });
        this.watchOrigin.next(this.origin);
        this.checkLanguage();
        resolve();
      } catch (e) {
        console.error(e);
      }

    })
  }

  async getLabelData(code: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        code = code.toLowerCase();
        //this.storyService.getArticles(this.origin);
        //this.storyService.getArticles(this.origin, false, true);
        //this.storyService.getStoryLevelCollection(this.origin);
        if (!this.onboardingService.isActive) await this.storyService.initAppWithStories(this.origin);

        if (code == 'eng') code = 'en';

        this.loadLocalLocalizationFile(code);

        this.http.get(`${environment.api}/labels/${code}`, {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          this.label = res;
          resolve();
        }, (err) => {
          reject(err);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  loadLocalLocalizationFile(code: string): void {
    this.http.get('../../assets/localizations/' + code + '.json').subscribe((data) => {
      this.label = data;
    }, (err) => {
      console.error("error loading locaization")
    });
  }

  loadTargetLanguage(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const target = await this._sqliteService.getItem(StorageKeys.TARGET_LANGUAGE) as string;
      if (target) {
        resolve(target)
      } else {
        resolve(undefined)
      }
    })
  }

  setCustomTargetLanguage(language: string) {
    this.setUserTargetLanguage(language);
    this.getLabelData(language);
  }

  getOriginFromStorage(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const origin = await this._sqliteService.getItem(StorageKeys.ORIGIN_LANGUAGE) as string;
      if (origin) {
        resolve(origin)
      } else {
        resolve(undefined)
      }
    })
  }

}
