import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SingleWord } from '../../interfaces/single-word';
import { environment } from 'environments/environment';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';
import WordSetting from '@interfaces/word-setting';
import { AuthService } from '@services/auth-service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WordService {

  public current_word = new Subject();
  public current_word_component: any;
  public data: any = {} as SingleWord;
  public wordSetting: WordSetting;
  public enlargeFont = false;
  public hideTranslation = false;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    this.languageService.watchOrigin.subscribe((origin) => {
      this.loadWordSettings();
    })
  }

  public getWordData(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/words/app/${id}`, {
        headers: this.authService.authHeader
      }).subscribe(result => {
        resolve(result);
      }, error => {
        console.log(error);
      });
    })
  }

  public getSingleWordTest(id, type, obj) {
    // Hebt die Makierung des aktuellen Wortes auf
    if (this.current_word_component) {
      this.current_word_component.deactivateWord();
    }

    this.http
      .get(
        environment.api +
        '/wp-content/plugins/wp_word_translation/controller/app_api_get_word.php?id=' +
        id +
        '&art=' +
        type,
      )
      .subscribe((data) => {
        if (data == null) {
          data = {
            google: obj.word,
          };
        }

        this.current_word.next(data);
        this.current_word_component = obj; // setzt das aktuell verwendete WordComponent
        this.data = data;
        this.current_word_component.activateWord();
      });
  }

  public setCurrentWordNull() {
    this.current_word_component.deactivateWord();
    this.current_word_component = null;
    this.current_word.next(null);
  }

  public getTranslation(word: Word): string {
    let enIndex;
    let targetLanguage = this.languageService.target.toLocaleLowerCase();

    if (targetLanguage == 'eng') targetLanguage = 'en';
    if (!word.translations) return '';
    if (targetLanguage == 'zh-hant') targetLanguage = 'zh-TW';
    if (targetLanguage == 'zh-hans') targetLanguage = 'zh';

    for (let index = 0; index < word.translations.length; index++) {
      if (word.translations[index].language == "en") enIndex = index; // remember the index of the english translation

      if (word.translations[index].language === targetLanguage) {
        if (word.translations[index].translations.length > 0) return word.translations[index].translations.join(', ');
        else if (word.translations[index].translations.length == 0) {  //if the target language is not found, return the english translation
          return word.translations[enIndex].translations.join(', ')
        } else return word.translations[index].text;
      }
    }

    if (enIndex != undefined) return word.translations[enIndex].translations.join(', ')

    return '';
  }

  public getForm(word: Word, type: string): string {
    if (!word.forms) return '';
    for (let index = 0; index < word.forms.length; index++) {
      if (word.forms[index].type === type) return word.forms[index].text;
    }
    return '';
  }

  public loadWordSettings(): void {
    if (!this.languageService.origin || this.languageService.origin == "") return;
    this.http.get(`${environment.api}/wordsettings/${this.languageService.origin}`, {
      headers: this.authService.authHeader
    }).subscribe((result: WordSetting) => {
      this.wordSetting = result;
    })
  }
  
}
