import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { LanguageService } from '@services/language-service/language.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { NotificationService } from '@services/notification-service/notification.service';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';

@Injectable({
  providedIn: 'root',
})
class OnboardingGuardService {
  constructor(
    private router: Router,
    private _sqliteService: SQLiteService,
    private onboardingService: OnboardingService,
    public languageService: LanguageService,
    public notificationService: NotificationService,
    public utilsService: UtilsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {

      /**
       * Currently the web app in incognito has no data so if a user opens that link and has no web app data they will be taken to the welcome page instead
       * Goal: put the learning language, device language and learning level in the deeplink url and redirect users to the story (skipping onboarding)
       * 
       * 
       * => Check the url for the learning language, device language and learning level
       * => If the user has no data in the web app, set the learning language, device language and learning level
       * => Redirect the user to the story (skip the onboarding by resolving true)
       */
      if (this.onboardingService.isActive) {
        return resolve(true);
      } else {
        this._sqliteService.getItem(StorageKeys.USER_LEVEL).then((val: string) => {
          if (val) {
            FirebaseAnalytics.setUserProperty({ key: 'Level', value: val });
            resolve(true);
          } else {
            if (route.routeConfig.path.includes("start-learning")) {
              this.onboardingService.promotionUrl = this.getResolvedUrl(route);
              this.onboardingService.fromPromotion = true;
            }
            this.router.navigate(['welcome']);
            //this.preloadStroies();
            resolve(false);
          }
        });
      }
    });
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let code;
    if (route.queryParams.codeYearly) {
      code = '&codeYearly=' + route.queryParams.codeYearly;
    } else if (route.queryParams.codeMonthly) {
      code = '&codeMonthly=' + route.queryParams.codeMonthly;
    } else if (route.queryParams.codeLifetime) {
      code = '&codeLifetime=' + route.queryParams.codeLifetime;
    }
    const routeURL = '/start-learning?source_caller' + route.queryParams.source_caller + '&shortlink=' + route.queryParams.shortlink + code + '&c=' + route.queryParams.c + '&pid=' + route.queryParams.pid + '&deep_link_value=' + route.queryParams.deep_link_value + '&af_xp=' + route.queryParams.af_xp;
    return routeURL
  }
}

export const OnboardingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(OnboardingGuardService).canActivate(next, state);
}
