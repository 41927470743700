import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { TrainingService } from '@services/training-service/training.service';
import { LanguageService } from '../../service/language-service/language.service';
import { Location } from '@angular/common';
import { UtilsService } from '@services/utils-service/utils.service';
import { SettingsService } from '@services/settings-service/settings.service';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() secondaryTitle = false;
  @Input() separetedTitle = false;
  @Input() settings = false;
  @Input() titleLeft = false;
  @Input() profile = false;
  @Input() back = false;
  @Input() trainingInfo = false;
  @Input() trainingOverview = false;
  @Input() learnedFilter = false;
  @Input() learnFilterState = false;
  @Input() nextLevelAvailable = true;
  @Input() previousLevelAvailable = true;
  @Input() trainingStats = false;
  @Input() SRSExplaination = false;

  pinYin = false;
  romaji = false;

  @Output() clearTrainingList = new EventEmitter<any>();
  @Output() toggleLearnedFilter = new EventEmitter<boolean>();
  @Output() previousLevel = new EventEmitter<any>();
  @Output() nextLevel = new EventEmitter<any>();
  @Output() onBack = new EventEmitter<any>();

  constructor(
    public navCrtl: NavController,
    public languageService: LanguageService,
    public trainingService: TrainingService,
    public location: Location,
    public platform: Platform,
    public utilsService: UtilsService,
    public settingsService: SettingsService,
    private _sqliteService: SQLiteService
  ) {
    this.settingsService.PinyinTraining.subscribe((value) => {
      this.pinYin = value;
    });
    this.settingsService.RomajiTraining.subscribe((value) => {
      this.romaji = value;
    });
  }

  ngOnInit() {
    this._sqliteService.getItem(StorageKeys.SHOW_PINYIN_IN_TRAINING).then((val:boolean) => {
      this.settingsService.showPinyinInTraining = val;
      this.pinYin = val
    })
    this._sqliteService.getItem(StorageKeys.SHOW_ROMAJI_IN_TRAINING).then((val:boolean) => {
      this.settingsService.showRomajiInTraining = val;
      this.romaji = val
    })
  }

  goBack(): void {
    if (!this.back) return;

    const currentUrl = this.location.path();

    if (currentUrl && currentUrl.includes('settings/')) {
      this.goToSettings(true);
      return
    }

    if (currentUrl && currentUrl.includes('settings')) {
      this.goToProfile(true);
      return
    }

    if (currentUrl && currentUrl.includes('training-exercise')) {
      this.trainingService.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
      this.trainingService.getTrainingSummary();
      this.goToTraining(true);
      return
    }

    if (currentUrl && currentUrl.includes('profile')) {
      if (this.utilsService.routingName == 'flashFiction') {
        this.navCrtl.navigateRoot("/short-stories-library");
      } else {
        this.navCrtl.navigateRoot("/home", { animationDirection: 'back' });
      }

      return
    }

    if (currentUrl && currentUrl.includes('favorites') || currentUrl && currentUrl.includes('learned-stories')) {
      this.navCrtl.navigateBack("/my-library", { animationDirection: 'back' });
      return
    }

    this.navCrtl.back();
    this.onBack.emit();
  }

  goToSettings(backAnimation: boolean = false): void {
    this.navCrtl.navigateForward('/settings', { animationDirection: backAnimation ? 'back' : 'forward' });
  }

  goToProfile(backAnimation: boolean = false): void {
    this.navCrtl.navigateForward('/profile', { animationDirection: backAnimation ? 'back' : 'forward' });
  }

  goToTraining(backAnimation: boolean = false): void {
    this.navCrtl.navigateForward('/training', { animationDirection: backAnimation ? 'back' : 'forward' });
  }

  deckSettings(): void {
    this.utilsService.deckSettings = true;
  }

  showSRSPoints(): void {
    this.utilsService.newTraining = true;
  }

  showTrainingInfo(): void {
    this.trainingService.showTrainingHelp = true;
  }

  clearTrainingListEvent(): void {
    this.clearTrainingList.emit();
  }

  triggerPreviousLevel(): void {
    if (this.previousLevelAvailable) this.previousLevel.emit();
  }

  triggerNextLevel(): void {
    if (this.nextLevelAvailable) this.nextLevel.emit();
  }


  togglePinyin() {
    if (this.settingsService.showPinyinInTraining) {
      this.settingsService.PinyinTraining.next(false);
      this._sqliteService.setItem(StorageKeys.SHOW_PINYIN_IN_TRAINING, false);
      this.settingsService.showPinyinInTraining = false;
      this.pinYin = false;
    } else {
      this.settingsService.PinyinTraining.next(true);
      this._sqliteService.setItem(StorageKeys.SHOW_PINYIN_IN_TRAINING, true)
      this.settingsService.showPinyinInTraining = true;
      this.pinYin = true;
    }
  }


  toggleRomaji() {
    if (this.settingsService.showRomajiInTraining) {
      this.settingsService.RomajiTraining.next(false);
      this._sqliteService.setItem(StorageKeys.SHOW_ROMAJI_IN_TRAINING, false)
      this.settingsService.showRomajiInTraining = false;
    } else {
      this.settingsService.RomajiTraining.next(true);
      this._sqliteService.setItem(StorageKeys.SHOW_ROMAJI_IN_TRAINING, true)
      this.settingsService.showRomajiInTraining = true;
    }
  }

}
