import { Component } from '@angular/core';
import { RatingService } from '../../../service/rating-service/rating.service';
import { LanguageService } from '../../../service/language-service/language.service';
import { environment } from 'environments/environment';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
})
export class RateComponent {
  environment = environment;

  constructor(
    public ratingService: RatingService,
    public languageService: LanguageService,
  ) {}

  public close(): void {
    this.ratingService.showOverlay = false;
  }

  public rate(): void {
    FirebaseAnalytics.logEvent({name: 'rate_user_popup', params: {}});
    this.ratingService.rate();
    this.close();
  }

  public feedback(): void {
    this.ratingService.feedback();
    this.close();
  }
}
