<div class="overlay"></div>
<div class="training-settings-container">
  <div class="training-settings-content">
    <ion-icon class="close-btn" name="close-outline" (click)="close()"></ion-icon>
    <h3>{{languageService.label.txt_training_settings_title}}</h3>
    <!-- <div class="tabs-container">
      <div class="tabs" (click)="toggleGeneral()" [ngClass]="{'active': generalSetting}">
        {{languageService.label.txt_training_settings_general_btn}}
      </div>
      <div class="tabs" (click)="toggleAdvanced()" [ngClass]="{'active': !generalSetting}">
        {{languageService.label.txt_training_settings_advanced_btn}}
      </div>
    </div> -->
    <div *ngIf="generalSetting">
      <ng-container>
        <div class="main-container">
          <ion-list class="list-settings">
            <ion-item lines="none">
              <ion-icon name="volume-medium-outline" slot="start"></ion-icon>
              <ion-label class="label-wrap">
                {{languageService.label.txt_training_settings_play_audio}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="togglePlayAudio()" [(ngModel)]="settingsService.playAudioAuto" slot="end"
                color="primary"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
              <ion-icon name="repeat-outline" slot="start"></ion-icon>
              <ion-label class="label-wrap">
                {{SwapSidesLabel}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="toggleSwapSides()" [(ngModel)]="settingsService.swapSidesCard" slot="end"
                color="primary"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
              <ion-icon name="reader-outline" slot="start"></ion-icon>
              <ion-label class="label-wrap">
                {{languageService.label.txt_training_settings_show_answer}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="toggleShowAnswer()" [(ngModel)]="settingsService.showAnswer" slot="end"
                color="primary"></ion-toggle>
            </ion-item>
            <ion-item lines="none" *ngIf="languageService.origin == 'ja'">
              <img *ngIf="!utilsService.isDarkMode" src="../../../assets/svg/font-a.svg" alt="" slot="start"
                width="24px" height="22px">
              <img *ngIf="utilsService.isDarkMode" src="../../../assets/svg/font-a-dark.svg" alt="" slot="start"
                width="24px" height="22px">
              <ion-label>
                {{languageService.label.txt_training_settings_show_romaji}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="toggleRomaji()" [(ngModel)]="romaji" slot="end" color="primary"></ion-toggle>
            </ion-item>
            <ion-item lines="none" *ngIf="languageService.origin == 'ja'">
              <span class="hiragana-icon" slot="start">あ</span>
              <ion-label>
                {{languageService.label.txt_japanese_hiragana}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="toggleHiragana()" [(ngModel)]="settingsService.showHiraganaInTraining"
                slot="end" color="primary"></ion-toggle>
            </ion-item>
            <ion-item lines="none" *ngIf="languageService.origin == 'zh-hans'">
              <img *ngIf="!utilsService.isDarkMode" src="../../../assets/svg/font-a.svg" alt="" slot="start"
                width="27px" height="22px">
              <img *ngIf="utilsService.isDarkMode" src="../../../assets/svg/font-a-dark.svg" alt="" slot="start"
                width="27px" height="22px">
              <ion-label>
                {{languageService.label.txt_training_settings_show_pinyin}}
              </ion-label>
              <ion-toggle legacy="true" (ngModelChange)="togglePinyin()" [(ngModel)]="pinYin" slot="end" color="primary"></ion-toggle>
            </ion-item>
          </ion-list>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!generalSetting">

    </div>

  </div>

</div>