<div class="wrapper">

  <div [ngClass]="{'hide': isSuccess}">
    <h1 class="title" *ngIf="!isPasswordInput">{{languageService.label.txt_verify_headline}}</h1>
    <h1 class="title" *ngIf="isPasswordInput">{{languageService.label.txt_register_placeholder_password}}</h1>
    <div class="description" *ngIf="!isPasswordInput">{{languageService.label.txt_verify_text}}</div>

    <form class="content-area">
      <div style=" display: flex; justify-content: center;" *ngIf="!isPasswordInput">
        <input type="number" name='code' class='code-input' maxlength="1" required />
        <input type="number" name='code' class='code-input' maxlength="1" required />
        <input type="number" name='code' class='code-input' maxlength="1" required />
        <input type="number" name='code' class='code-input' maxlength="1" required />
        <input type="number" name='code' class='code-input' maxlength="1" required />
        <input type="number" name='code' class='code-input' maxlength="1" required />
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">

        <div class="control-item" *ngIf="isPasswordInput">
          <!-- <p class="label">{{languageService.label.txt_register_placeholder_password}}</p> -->
          <ion-item lines="none">
            <ion-input legacy="true" type="password" formControlName="password"
              placeholder="{{languageService.label.txt_verify_try_password_placeholder}}"></ion-input>
          </ion-item>
          <div class="forgot-password" (click)="forgotPassword()">
            {{languageService.label.txt_login_modal_forgot_password}}
          </div>
        </div>

        <p *ngIf="!isPasswordInput" class="error" [ngClass]="{'active': isError}">
          {{languageService.label.txt_verify_message_error}}</p>
        <p *ngIf="isPasswordInput" class="error" [ngClass]="{'active': isError}">
          {{languageService.label.txt_login_modal_message_error}}</p>

        <button (click)="onSubmit($event)" type="submit" class="submit">
          <span *ngIf="!isPending && isPasswordInput">{{languageService.label.txt_login_modal_txt}}</span>
          <span *ngIf="!isPending && !isPasswordInput">{{languageService.label.txt_verify_cta}}</span>
          <ion-spinner *ngIf="isPending " name="crescent"></ion-spinner>
        </button>

        <div class="resend-wrapper">
          <div *ngIf="isResendSuccess && !isPasswordInput">
            <ion-icon style="margin-right: 4px;" name="checkmark-circle"></ion-icon><span
              style="margin-right: 4px;">{{languageService.label.txt_verify_resend_success}}
            </span>
          </div>
          <a class="inline-span" *ngIf="!isResendDisabled && !isPasswordInput" (click)="resendVerificationCode()">
            <span>{{languageService.label.txt_verify_resend}}</span>
          </a>
          <div *ngIf="isResendError && !isPasswordInput">{{languageService.label.txt_verify_resend_error}}</div>
          <div *ngIf="isResendDisabled && !isPasswordInput">{{languageService.label.txt_verify_resend_disabled}}
            (00:{{timer}})</div>
        </div>
        <div class="resend-wrapper" *ngIf="!createVerify">
          <a class="inline-span" (click)="tryPassword()">
            <span>{{languageService.label.txt_verify_try_password}}</span>
          </a>
        </div>
        <div class="resend-wrapper">
          <a class="inline-span" (click)="goBack()">
            <span>{{this.languageService.label.txt_verify_use_other_email}}</span>
          </a>
        </div>
      </form>
    </form>
  </div>

  <ng-container *ngIf="isSuccess">
    <h1 class="title" *ngIf="authService.newAccount">{{languageService.label.txt_verify_success_headline}}</h1>
    <h1 class="title" *ngIf="!authService.newAccount" [innerHTML]="WelcomeMsg()"></h1>

    <div class="description" *ngIf="authService.newAccount">{{languageService.label.txt_verify_success_text}}</div>
    <div class="description" *ngIf="!authService.newAccount">{{txt_login_modal_success_text_alt}}</div>
    <div class="content-area">
      <button (click)="continue()">
        <span>{{languageService.label.txt_verify_success_cta}}</span>
      </button>
    </div>
  </ng-container>


</div>