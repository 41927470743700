import { Injectable } from '@angular/core';
import { Market } from '@awesome-cordova-plugins/market';
import { AppRate, AppRatePreferences } from '@awesome-cordova-plugins/app-rate';
import { Platform } from '@ionic/angular';
import { LanguageService } from '../language-service/language.service';
import { environment } from 'environments/environment';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { NotificationService } from '@services/notification-service/notification.service';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';
import { StoryService } from '@services/story-service/story.service';
import { Device } from '@capacitor/device';
import { TrainingService } from '@services/training-service/training.service';

interface AppRatePreferencesEnhanced extends AppRatePreferences {
  openUrl: (url: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  private clickedArticles: number;
  public showOverlay = false;
  public userHasRated = true;
  public appStarts = 0;
  public displayRatingHomeRatingComponent = false;
  public ratingOpenFrom = 0; // 0 = after app start, 1 = return from article
  public unlockStory: any;
  public ratingEntered = false;

  constructor(
    private _sqliteService: SQLiteService,
    private platform: Platform,
    private storyService: StoryService,
    private languageService: LanguageService,
    private trainingService: TrainingService,
    private launchReview: LaunchReview,
    private notificationService: NotificationService
  ) {
    this.clickedArticles = 0;
  }

  public checkAppStarts(): void {
    this._sqliteService.getItem(StorageKeys.APP_STARTS).then((val: number) => {
      if (val) {
        this.appStarts = val + 1;
        this._sqliteService.setItem(StorageKeys.APP_STARTS, this.appStarts);
        this.shouldRatingComponentDisplayed();
        this.shouldRatingOverlayDisplayed();
        if (this.appStarts <= 10) this.trainingService.enablePrompt = true;
      } else {
        this._sqliteService.setItem(StorageKeys.APP_STARTS, 1);
        this.appStarts = 1;
        this.trainingService.enablePrompt = true;
      }
    });
  }

  public shouldRatingComponentDisplayed(): void {
    if (this.appStarts % 3 == 0 && this.appStarts != 0) {
      this.displayRatingHomeRatingComponent = true;
    }
  }

  public shouldRatingOverlayDisplayed(): void {
    if (this.userHasRated || this.notificationService.nextStoryFree) {
      return;
    }
    if (
      this.appStarts == 3 ||
      this.appStarts == 6 ||
      this.appStarts == 10 ||
      this.appStarts == 20 ||
      this.appStarts == 40
    ) {
      this.showOverlay = true;
    }
  }

  public increaseClickedArticles(): void {
    this.clickedArticles++;
    this.checkShowOverlay();
  }

  private checkShowOverlay(): void {
    console.log('DEBUG_checkShowOverlay  ' + this.clickedArticles);
    if (this.clickedArticles % 3 === 0 && !(this.clickedArticles % 6 === 0) && (this.storyService.freeArticlesOpened <= 2)) {
      this._sqliteService.getItem(StorageKeys.USER_RATED).then((val: boolean) => {
        if (!val) {
          this.ratingOpenFrom = 1;
          this.showOverlay = true;
        }
      });
    }
  }

  public markUserAsRated(): void {
    this._sqliteService.setItem(StorageKeys.USER_RATED, true);
    this.userHasRated = true;
  }

  public hasUserRated(): void {
    this._sqliteService.getItem(StorageKeys.USER_RATED).then((val: boolean) => {
      if (val) {
        this.userHasRated = true;
        return true;
      } else {
        this.userHasRated = false;
        return false;
      }
    });
  }

  public rate(stars?: number): void {
    //this.directToAppStore();
    this.launchReview.launch();
    this.markUserAsRated();
    return;
  }

  public directToAppStore(): void {
    if (this.platform.is('ios')) {
      Market.open(environment.rating.appIdIos);
    } else {
      Market.open(environment.rating.appIdAndroid);
    }
  }

  public async feedback(): Promise<void> {
    const info = await Device.getInfo();
    const uuid = (await Device.getId()).identifier;

    let body = '';
    body = body + 'Description: <Please fill your feedback here>';
    body = body + '<br/><br/><b>System Info:</b><br/>';
    body = body + 'Model: ' + info.model + '<br/>';
    body = body + 'Platform: ' + info.platform + '<br/>';
    body = body + 'UUID: ' + uuid + '<br/>';
    body = body + 'Version: ' + info.osVersion + '<br/>';
    body = body + 'Manufacturer: ' + info.manufacturer + '<br/>';

    this.showOverlay = false;
  }

  public setupNativeRating(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    const label = this.languageService.label;

    const preferences: AppRatePreferencesEnhanced = {
      displayAppName: environment.rating.displayName,
      usesUntilPrompt: 5,
      promptAgainForEachNewVersion: false,
      simpleMode: true,
      storeAppURL: {
        ios: environment.rating.appIdIos,
        android: `market://details?id=${environment.rating.appIdAndroid}`,
        windows: 'ms-windows-store://pdp/?ProductId=<the apps Store ID>',
        blackberry: 'appworld://content/[App Id]/',
        windows8: 'ms-windows-store:Review?name=<the Package Family Name of the application>',
      },
      customLocale: {
        title: label.rating_native_title,
        message: label.rating_native_message,
        cancelButtonLabel: label.rating_native_cancelButton,
        laterButtonLabel: label.rating_native_laterButton,
        rateButtonLabel: label.rating_native_rateButton,
        yesButtonLabel: label.rating_native_yesButton,
        noButtonLabel: label.rating_native_noButton,
        appRatePromptTitle: label.rating_native_appRatePromptTitle,
        feedbackPromptTitle: label.rating_native_feedbackPromptTitle,
        appRatePromptMessage: '',
        feedbackPromptMessage: '',
      },
      callbacks: {
        handleNegativeFeedback: function () {
          console.log('DEBUG_NEGATIVE_FEEDBACK_INCOMIMG');
          window.open(`mailto:${environment.support.mail}`, '_system');
        },
        onRateDialogShow: function (callback) {
          console.log('DEBUG_ON_RATE_DIALOG');
          callback(1); // cause immediate click on 'Rate Now' button
        },
        onButtonClicked: function (buttonIndex) {
          console.log('DEBUG_onButtonClicked -> ' + buttonIndex);
        },
      },
      openUrl: AppRate.getPreferences().openUrl,
      useLanguage: 'en',
    };

    AppRate.setPreferences(preferences);
    AppRate.promptForRating(true);
  }
}
