import { Component, AfterViewInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { StoryService } from '../../service/story-service/story.service';
import { LanguageService } from '../../service/language-service/language.service';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine';
import { Quiz } from '../../interfaces/story';
import { WordService } from './../../service/word-service/word.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { environment } from 'environments/environment';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { SQLiteService } from '@services/sqlite.service';
import { StorageKeys } from '@interfaces/storage.keys.interface';
import { IonicSlides } from '@ionic/angular';
import { Swiper } from 'swiper/types';

@Component({
  selector: 'quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements AfterViewInit {
  swiperModules = [IonicSlides];

  environment = environment;
  currentQuestion = 1;
  resultCorrectAnswers = 0;
  incorrectAnswers = 0;
  progressText = '1 / 4';
  tmp_lock = false;
  isAudio = true;
  resultText = "";
  totalQuestions = 0;
  questionLast = false;
  badge = false;
  trophy = false;
  resultSubText = '';
  userAnswers: number[] = []
  realAnswers: boolean[] = []
  final_score = '0%';

  slideOpts = {
    allowTouchMove: false,
    observer: true,
    observeParents: true,
  };

  public didInit = false;

  @ViewChild('swiper') swiperRef: ElementRef;

  @Input() quiz: Quiz;
  @Output() quizData = new EventEmitter<any>();
  @Output() questionChange = new EventEmitter<any>();

  get swiper(): Swiper {
    return this.swiperRef.nativeElement.swiper;
  }

  constructor(
    public articleService: StoryService,
    public languageService: LanguageService,
    public wordService: WordService,
    public utilsService: UtilsService,
    public onboardingService: OnboardingService,
    private _sqliteService: SQLiteService,
  ) { }

  ngAfterViewInit(): void {
    this.didInit = true;
    //this.nativeAudio.preloadSimple('correct', 'assets/sounds/correct_sound.mp3');
    this._sqliteService.getItem(StorageKeys.QUIZ_AUDIO).then((val: boolean) => {
      if (val != null)
        this.isAudio = val;
    });
    this.totalQuestions = this.quiz.questions.length
  }

  startQuiz(): void {
    this.swiper.update();
    this.showProgressbar();
    this.currentQuestion = 1;
    this.renderProgressbar();
  }

  ngOnDestroy(): void {
    this.badge = false;
    this.trophy = false;
    this.articleService.quizLast = false;
    this.articleService.quizTab = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  checkAnswer(id, correct, el, x): void {
    if (this.tmp_lock) {
      return;
    }
    this.quizData.emit({ currentLevel: this.currentQuestion, questionState: correct ? 'correct' : 'incorrect' });
    this.tmp_lock = true;
    TapticEngine.impact({ style: 'medium' });
    this.currentQuestion++;

    // Todo: This call causes error, result quiz not showing result screen
    // if (this.currentQuestion > this.questions.length) this.authService.increaseUserdataQuiz();

    this.userAnswers.push(x);
    if (correct) {
      el.classList.add('answer-correct');
      if (this.isAudio) {
        const audio = new Audio();
        audio.src = 'assets/sounds/correct_sound.mp3';
        audio.load();
        audio.play();
      }
      this.resultCorrectAnswers++;
      this.realAnswers.push(true)
    } else {
      el.classList.add('answer-false');
      document
        .getElementsByClassName('quiz-answers')
      [id].getElementsByClassName('quiz-answer')
      [this.getIdOfCorrectAnswer(id)].classList.add('answer-correct');
      this.realAnswers.push(false)
    }

    setTimeout(() => {
      this.tmp_lock = false;

      if (this.currentQuestion > this.quiz.questions.length) {
        this.renderResultSlide();
        this.hideProgressbar();
        this.articleService.quizLast = true;
        this.articleService.quizTab = true;
      } else {
        this.articleService.quizLast = false;
      }

      this.swiper.slideNext();
      this.renderProgressbar();
      this.quizData.emit({ currentLevel: this.currentQuestion, questionState: '' });
      this.questionChange.emit(this.currentQuestion);
    }, 1000);
  }

  getIdOfCorrectAnswer(questionId: number): number {
    for (let index = 0; index < this.quiz.questions[questionId].answers.length; index++) {
      const element = this.quiz.questions[questionId].answers[index];
      if (element.correct) {
        return index;
      }
    }
  }

  renderResultSlide(): void {
    const percentage = this.resultCorrectAnswers / this.totalQuestions
    this.incorrectAnswers = this.totalQuestions - this.resultCorrectAnswers
    if (percentage == 0) {
      this.badge = false;
      this.trophy = false;
      this.resultText = this.languageService.label.txt_quiz_result_0_title;
      this.resultSubText = this.languageService.label.txt_quiz_result_0_text;
    }
    else if (percentage > 0 && percentage < 0.34 || this.resultCorrectAnswers == 1) {
      this.badge = false;
      this.trophy = false;
      this.resultText = this.languageService.label.txt_quiz_result_1_title;
      this.resultText = this.resultText.replace("[TotalAnswer]", this.totalQuestions.toString());
      this.resultText = this.resultText.replace("[correctAnswer]", this.resultCorrectAnswers.toString());
      this.resultSubText = this.languageService.label.txt_quiz_result_1_text;
    } else if (percentage >= 0.34 && percentage < 0.67 || (this.resultCorrectAnswers > 1 && percentage <= 0.34)) {
      this.badge = true;
      this.trophy = false;
      this.resultText = this.languageService.label.txt_quiz_result_2_title;
      this.resultSubText = this.languageService.label.txt_quiz_result_2_text + "<br>" + this.languageService.label.txt_quiz_result_2_text2;
      this.resultSubText = this.resultSubText.replace("[TotalAnswer]", this.totalQuestions.toString());
      this.resultSubText = this.resultSubText.replace("[correctAnswer]", this.resultCorrectAnswers.toString());
    } else if (percentage >= 0.67 && percentage < 1) {
      this.trophy = true;
      this.badge = false;
      this.resultText = this.languageService.label.txt_quiz_result_3_title;
      this.resultSubText = this.languageService.label.txt_quiz_result_2_text + "<br>" + this.languageService.label.txt_quiz_result_3_text;
      this.resultSubText = this.resultSubText.replace("[TotalAnswer]", this.totalQuestions.toString());
      this.resultSubText = this.resultSubText.replace("[correctAnswer]", this.resultCorrectAnswers.toString());
    } else if (percentage == 1) {
      this.trophy = true;
      this.badge = false;
      this.resultText = this.languageService.label.txt_quiz_result_4_title;
      this.resultSubText = this.languageService.label.txt_quiz_result_2_text + "<br>" + this.languageService.label.txt_quiz_result_4_text;
      this.resultSubText = this.resultSubText.replace("[TotalAnswer]", this.totalQuestions.toString());
      this.resultSubText = this.resultSubText.replace("[correctAnswer]", this.resultCorrectAnswers.toString());
    }
    const r = document.querySelector(':root') as HTMLElement;
    this.final_score = (percentage * 100).toFixed(2) + '%';
    r.style.setProperty('--quiz-percentage', this.final_score);
  }

  renderProgressbar(): void {
    /* const progess = (this.currentQuestion / this.quiz.questions.length) * 100;
    this.progressText = '' + this.currentQuestion + ' / ' + this.quiz.questions.length;
    document.getElementById('progression-bar').setAttribute('style', 'width: ' + progess + '%'); */
  }

  hideProgressbar(): void {
    document.getElementsByClassName('quiz-progression')[0].setAttribute('style', 'display: none');
  }

  showProgressbar(): void {
    document.getElementsByClassName('quiz-progression')[0].setAttribute('style', 'display: block');
  }

  replayQuiz(): void {
    this.userAnswers = []
    this.realAnswers = []
    this.articleService.quizLast = false;
    this.shuffleQuestions();
    this.removeAnswerResultClasses();
    this.currentQuestion = 1;
    this.resultCorrectAnswers = 0;
    this.renderProgressbar();
    this.showProgressbar();
    this.quizData.emit({ currentLevel: this.currentQuestion, questionState: '' });
    document.getElementById('article-lower-element').scrollTo(0, 0);
  }

  removeAnswerResultClasses(): void {
    const list = document.getElementsByClassName('quiz-answer');
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      element.classList.remove('answer-correct');
      element.classList.remove('answer-false');
    }
  }

  shuffleQuestions(): void {
    let currentIndex = this.quiz.questions.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = this.quiz[currentIndex];
      this.quiz[currentIndex] = this.quiz[randomIndex];
      this.quiz[randomIndex] = temporaryValue;
    }
  }

  public segmentChanged(): void {
    if (this.swiper.isEnd) {
      this.replayQuiz();
    }
  }

  toggleQuizAudio(): void {
    this.isAudio = !this.isAudio;
    TapticEngine.impact({ style: 'light' });
    this._sqliteService.setItem(StorageKeys.QUIZ_AUDIO, this.isAudio);
  }
}
