import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { DaysUntilPipe } from './days-until.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { StoryTitlePipe } from './story-title.pipe';
import { TranslationPipe } from './word/translation.pipe';
import { WordFormPipe } from './word/word-form.pipe';
import { ReplaceLinebreakPipe } from './replace-linebreak.pipe';
import { WordInTrainingPipe } from './word/word-in-training.pipe';
import { LevelPipePipe } from './level-pipe.pipe';
import { ArticlePipe } from './word/article.pipe';
import { VirtualListIndexPipe } from './virtual-list-index.pipe';
import { WordCategoryLabelPipe } from './word-category-label.pipe';
import { JAWordCategoryLabelPipe } from './ja-word-category-label.pipe';
import { KeywordPipe } from './keyword.pipe';
import { TextPreviewPipe } from './story/text-preview.pipe';
import { CustomDatePipe } from './util/custom-date.pipe';
import { StoryListFreePipe } from './story/story-list-free.pipe';
import { StoryShowGrammarPipe } from './story/story-show-grammar.pipe';
import { TrainingCategoryPipe } from './word/training-category.pipe';
import { InsertPricePipe } from './insert-price.pipe';
import { InsertMultiplePricesPipe } from './insert-multiple-prices.pipe';
import { CreditCardPurchasePrice } from './credit-card-purchase-price.pipe';
import { ExplainationPipe } from './word/explaination.pipe';
import { TransformCurrencyAmountPipe } from './transform-currency-amount.pipe';
import { UserStreakHighest } from './user-streak-highest';
import { UserStreakCurrent } from './user-streak-current';
import { userOpenedTotal } from './user-streak-opened';
import { userStreakBlock } from './user-streak-block';
import { WordFuriganaPipe } from './word-furigana.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DaysUntilPipe,
        SafeHtmlPipe,
        StoryTitlePipe,
        TranslationPipe,
        WordFormPipe,
        ReplaceLinebreakPipe,
        WordInTrainingPipe,
        LevelPipePipe,
        ArticlePipe,
        VirtualListIndexPipe,
        WordCategoryLabelPipe,
        JAWordCategoryLabelPipe,
        KeywordPipe,
        TextPreviewPipe,
        CustomDatePipe,
        StoryListFreePipe,
        StoryShowGrammarPipe,
        TrainingCategoryPipe,
        ExplainationPipe,
        ReplaceLinebreakPipe,
        InsertPricePipe,
        InsertMultiplePricesPipe,
        CreditCardPurchasePrice,
        TransformCurrencyAmountPipe,
        UserStreakHighest,
        UserStreakCurrent,
        userOpenedTotal,
        userStreakBlock,
        WordFuriganaPipe
    ],
    exports: [
        DaysUntilPipe,
        SafeHtmlPipe,
        StoryTitlePipe,
        TranslationPipe,
        WordFormPipe,
        ReplaceLinebreakPipe,
        WordInTrainingPipe,
        LevelPipePipe,
        ArticlePipe,
        VirtualListIndexPipe,
        WordCategoryLabelPipe,
        JAWordCategoryLabelPipe,
        KeywordPipe,
        TextPreviewPipe,
        CustomDatePipe,
        StoryListFreePipe,
        StoryShowGrammarPipe,
        TrainingCategoryPipe,
        ExplainationPipe,
        ReplaceLinebreakPipe,
        InsertPricePipe,
        InsertMultiplePricesPipe,
        CreditCardPurchasePrice,
        TransformCurrencyAmountPipe,
        UserStreakHighest,
        UserStreakCurrent,
        userOpenedTotal,
        userStreakBlock,
        WordFuriganaPipe
    ],
    providers: [
        CurrencyPipe
    ]
})
export class PipesModule { }
