export enum PurchaseTracking {
    EMPTY = '',
    LIBRARY = 'library_modal',
    FREESTORYINFO = 'free_story_info',
    READMOREDIALOGUE = 'readmore_dialogue',
    READMORESTORY = 'readmore_story',
    READMORESHORTSTORY = 'readmore_shortstory',
    POPUP = 'popup',
    NAVIGATION = 'navigation',
    NAVIGATIONBOOKS = 'navigation_books',
    SETTINGS = 'settings',
}