<div class="overlay"></div>
<div class="container">

  <div class="content">
    <div class="back-close" (click)="close()">
      <ion-icon name="close-outline"></ion-icon>
    </div>
    <div class="title" [innerHTML]="languageService.label.txt_feature_article"></div>

    <p>
      {{languageService.label.txt_free_stories_info_text}}
    </p>
    <button class="submit-btn" (click)="goToShop()"
      [innerHTML]="languageService.label.txt_free_stories_info_cta"></button>
  </div>

</div>