<div class="quiz-wrapper" [ngClass]="{'no-edge': articleService.quizLast, 'webview': !this.utilsService.isWebVersion}">
  <div class="quiz-progression">
    <div class="quiz-controll-wrapper">
      <!-- <div>
          <span style="display: inline-block; vertical-align: middle;">{{languageService.label.txt_quiz_questions}}</span>
          <span id="progression-text">{{ progressText }}</span>
        </div> -->
      <div (click)="toggleQuizAudio()" class="quiz-audio-btn" *ngIf="!utilsService.isWebVersion">
        <ng-container *ngIf="isAudio">
          <img *ngIf="!environment.single" src="assets/icon/volume-high-primary.svg" width="30px" height="20px"
            alt="Training">
          <img *ngIf="environment.single" src="assets/icon/volume-high-primary-readle.svg" width="30px" height="20px"
            alt="Training">
        </ng-container>
        <ng-container *ngIf="!isAudio">
          <img *ngIf="!environment.single" src="assets/icon/volume-mute.svg" width="30px" height="20px" alt="Training">
          <img *ngIf="environment.single" src="assets/icon/volume-mute-readle.svg" width="30px" height="20px"
            alt="Training">
        </ng-container>
      </div>
    </div>
    <!-- <div class="progression-bar-wrapper">
          <div id="progression-bar"></div>
        </div> -->
  </div>

  <swiper-container *ngIf="didInit && !articleService.quizLast" #swiper id="slider" [modules]="swiperModules"
    [allowTouchMove]="false" [observer]="true" [observeParents]="true">
    <!--QUESTION SLIDES-->
    <swiper-slide [ngClass]="{'bg': onboardingService.isTutorial}"
      *ngFor="let question of quiz.questions; let i = index">
      <div class="quiz-question" [innerHTML]="question.question"
        [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
      </div>

      <div class="quiz-answers" [ngClass]="{'tutorial':this.onboardingService.isTutorial}">
        <div class="quiz-answer" *ngFor="let answer of question.answers, let x = index"
          (click)="checkAnswer(i, answer.correct, $event.target, x)" [innerHTML]="answer.answer"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        </div>
      </div>
    </swiper-slide>
    <!--END SLIDE-->
  </swiper-container>

  <div [ngClass]="{'bg': onboardingService.isTutorial, 'webview': this.utilsService.isWebVersion}"
    *ngIf="articleService.quizLast" [class]="'results-bubble ' + 'correct-answers-' + resultCorrectAnswers">
    <div id="quiz-result-text">
      <div>
        <div class="results-header" [ngClass]="{'biggerFont': this.wordService.enlargeFont}" [innerHTML]="resultText">
        </div>
        <div class="results-subheader" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
          [innerHTML]="resultSubText"></div>
      </div>
      <div>
        <lottie-player *ngIf="badge && !environment.single" class="player badge" src="../../../assets/lottie/badge.json"
          background="transparent" speed="0.5" autoplay>
        </lottie-player>
        <lottie-player *ngIf="badge && environment.single" class="player badge"
          src="../../../assets/lottie/badge_readle.json" background="transparent" speed="0.5" autoplay>
        </lottie-player>
        <lottie-player *ngIf="trophy" class="player" src="../../../assets/lottie/trophy.json" background="transparent"
          speed="1" autoplay>
        </lottie-player>
      </div>
    </div>
    <div class="container">
      <div class="results-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        {{languageService.label.btn_quiz_results}}</div>
      <div class="results-container">

        <div class="progress-bar">
          <span class="percent_label">{{this.final_score}}</span>
          <progress value="75" min="0" max="100" style="visibility:hidden;height:0;width:0;"></progress>
        </div>
        <div class="results-label correct" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_quiz_result_correct}}</div>
        <div class="correct-answers">{{resultCorrectAnswers}}</div>
        <div class="results-label incorrect" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{languageService.label.txt_quiz_result_incorrect}}</div>
        <div class="incorrect-answers">{{incorrectAnswers}}</div>

      </div>
      <div class="quiz-btn" (click)="replayQuiz()" [innerHTML]="this.languageService.label.btn_quiz_repeat"></div>
      <div class="scroll-container">
        <div class="scroll-grey" [innerHTML]="languageService.label.btn_quiz_scroll_review"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}"></div>
        <div class="scroll-grey" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          <span> <ion-icon name="arrow-down-circle-outline"></ion-icon></span>
        </div>
      </div>
      <div class="results-title" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        {{languageService.label.btn_quiz_answers}}</div>
      <div class="answers-container" *ngFor="let question of quiz.questions, let x = index">
        <div class="question-review" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          <div [innerHTML]="question.question"> </div>
          <img *ngIf="realAnswers[x]" src="assets/quiz/quiz-correct.svg" width="20px" height="20px">
          <img *ngIf="!realAnswers[x]" src="assets/quiz/quiz-incorrect.svg" width="20px" height="20px">
        </div>


        <div *ngFor="let answers of question.answers, let i = index"
          [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          <div class="dividerLine"></div>

          <div [innerHTML]="answers.answer"
            [ngClass]="{'correct-answer-color': answers.correct, 'incorrect-answer-color': (this.userAnswers[x] == i &&!answers.correct), 'biggerFont': this.wordService.enlargeFont}">
          </div>
        </div>

      </div>
      <div class="quiz-btn" (click)="replayQuiz()" [innerHTML]="this.languageService.label.btn_quiz_repeat"></div>
    </div>

  </div>

</div>