import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
    public onAppResume: Subject<void> = new Subject<void>();
    public onAppPause: Subject<void> = new Subject<void>();

    private readonly minTimeForReloadKey = '__langster_app_closed_time';
    private readonly minTimeForReloadMS = 3 * 60 * 60 * 1000;

    constructor(
        private platform: Platform,
        private storage: Storage
    ) {}

    public appResume() {
        this.onAppResume.next();

        if (this.platform.is('ios')) {
            this.reloadAppIfNeeded();
        }
    }

    public appPause() {
        this.onAppPause.next();

        if (this.platform.is('ios')) {
            this.storage.set(this.minTimeForReloadKey, Date.now());
        }
    }
 
    private async reloadAppIfNeeded() {
        if (this.platform.is('ios') && await this.needToReload()) {
            window.location.reload();
        }
    }

    private async needToReload(): Promise<boolean> {
        const appCLosedAt = await this.storage.get(this.minTimeForReloadKey) || Date.now();
        return Date.now() - appCLosedAt > this.minTimeForReloadMS;
    }
}