import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { AuthService } from '../auth-service/auth.service';
import { environment } from 'environments/environment';
import OneSignal, { NotificationClickEvent } from 'onesignal-cordova-plugin';
import { Purchases } from '@revenuecat/purchases-capacitor';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  public nextStoryFree: boolean = false;


  constructor(
    // private platform: Platform,
    private languageService: LanguageService,
    private storageService: StorageService,
    private purchaseService: PurchaseService,
    private authService: AuthService,
    private http: HttpClient,
    private navController: NavController,
    private platform: Platform
  ) {
    this.purchaseService.userPremiumStatus.subscribe(status => {
      if (status) this.sendTagsToOneSignal();
    })
  }

  OneSignalInit(): void {
    if (!this.platform.is('cordova')) return;

    OneSignal.initialize(environment.onesignal.appId);

    const listener = (event: NotificationClickEvent) => {
      const url = event.result.url;
      const regex = /article\/language\/\d+/;
      const match = url.match(regex);

      if (match) {
        this.nextStoryFree = true;
        this.navController.navigateForward(match[0], {
          animated: true,
          queryParams: {
            utmSource: 'onesignal',
            utmMedium: 'push',
            utmCampaign: 'test_campaign'
          }
        });
      }
    };
    OneSignal.Notifications.addEventListener("click", listener);
    OneSignal.Debug.setLogLevel(5);
  }

  OneSignalWeb() {
    let playerID = '';
    //create/update a user in OS 
    try {
      return new Promise((resolve, reject) => {
        this.http.post(`https://onesignal.com/api/v1/players`,
          {
            "app_id": environment.onesignal.appId,
            "device_type": 11,
            "language": this.languageService.target,
            "tags": {
              "learning_language": this.languageService.origin,
              "device_language": this.languageService.target,
              "level": this.storageService.userLevel,
              "premium": this.purchaseService.userPremium,
            },
            "notification_types": 1,
            "external_user_id": this.authService.user?.userId || null,
            "identifier": this.authService.user?.email
          },
          { headers: { accept: 'application/json', 'Content-Type': 'application/json' } }).subscribe((res: any) => {
            //get playerid
            playerID = res.id;
            //use player id to check the first_medium tag
            this.http.get(`https://onesignal.com/api/v1/players/${playerID}?app_id=${environment.onesignal.appId}`,
              {
                headers: new HttpHeaders({
                  Authorization: `Basic NjY3MThhNTItMDQ2ZC00ZjdjLTg2MjktMGIwZjlmNWQ4NTYx`,
                  'Content-Type': 'application/json; charset=utf-8'
                })
              }).subscribe((res: any) => {
                console.log(res)
                this.authService.osTags = res.tags;
                //if no value in first_medium tag then set to web 
                if (!this.authService.osTags.first_medium) {
                  this.http.post(`https://onesignal.com/api/v1/players`,
                    {
                      "app_id": environment.onesignal.appId,
                      "device_type": 11,
                      "language": this.languageService.target,
                      "tags": {
                        "first_medium": this.authService.first_medium,
                      },
                      "notification_types": 1,
                      "external_user_id": this.authService.user?.userId || null,
                      "identifier": this.authService.user?.email
                    },
                    { headers: { accept: 'application/json', 'Content-Type': 'application/json' } }).subscribe((res: any) => {
                      resolve(res);
                    }, (error) => {
                      reject("Error Sending Tags to Onesignal")
                    });
                }
                resolve(res);
              }, (error) => {
                reject("Error Getting Tags to Onesignal")
              });
            resolve(res);
          }, (error) => {
            reject("Error Sending Tags to Onesignal")
          });

      })
    } catch (err) { console.log(err) }

  }

  initUserNotificationPromt(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.Notifications.requestPermission(true).then((accepted) => {
        resolve(accepted);
      }).catch((e) => {
        console.log('💀 Error requestion notification permission', JSON.stringify(e));
      });

      this.sendTagsToOneSignal();

      //Send OneSignal PlayerID to RevenueCat
      OneSignal.Notifications.getPermissionAsync().then(async (state) => {
        if (state) {
          Purchases.setOnesignalID({ onesignalID: await OneSignal.User.getExternalId() })
        }
      })
    });
  }

  sendTagsToOneSignal() {
    if (!this.platform.is('cordova')) {
      if (!this.authService.user) return;
      return new Promise((resolve, reject) => {
        this.http.put(`https://onesignal.com/api/v1/apps/${environment.onesignal.appId}/users/${this.authService.user.userId}`,
          {
            "tags": {
              learning_language: this.languageService.origin,
              device_language: this.languageService.target,
              level: this.storageService.userLevel,
              premium: this.purchaseService.userPremium,
            }
          },
          { headers: this.authService.authHeader }).subscribe((res: any) => {
            resolve(res);
          }, (error) => {
            reject("Error Sending Tags to Onesignal")
          });
      })

    } else {
      try {
        //update tags
        OneSignal.User.addTags({
          learning_language: this.languageService.origin,
          device_language: this.languageService.target,
          level: this.storageService.userLevel,
          premium: this.purchaseService.userPremium,
        })
        //check tags
        OneSignal.User.getTags().then((tags) => {
          console.log('Tags Received: ' + JSON.stringify(tags));
          // if there is no first_medium tag then set to app 
          if (!JSON.stringify(tags).includes('first_medium')) {
            console.log('Tags dont contains first medium: ');
            try {
              OneSignal.User.addTags({
                first_medium: this.authService.first_medium
              })
            } catch (error) {
              console.log(error)
            }
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

}