import { Component, HostListener, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { LanguageService } from './service/language-service/language.service';
import { RatingService } from './service/rating-service/rating.service';
import { StoryService } from './service/story-service/story.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { environment } from '../environments/environment';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility';
import { PurchaseService } from './service/purchase-service/purchase.service';
import { NotificationService } from './service/notification-service/notification.service';
import { AuthService } from './service/auth-service/auth.service';
import { TrainingService } from './service/training-service/training.service';
import { UtilsService } from './service/utils-service/utils.service';
import { StorageService } from './service/storage-service/storage.service';
import { WordService } from '@services/word-service/word.service';
import { ScreensizeService } from '@services/screensize-service/screensize.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { ArticlePage } from '@pages/article/article.page';
import { ResetPasswordPage } from '@pages/reset-password/reset-password.page';
import { interval, timer } from 'rxjs';
import Bugsnag from '@bugsnag/js'
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '@services/theme-service/theme.service';
import { TrainingPage } from '@pages/training/training.page';
import { ProfilePage } from '@pages/profile/profile/profile.page';
import { CreditCardPurchasePage } from '@pages/credit-card-purchase/credit-card-purchase.page';
import { FirebaseRemoteConfig, SetSettingsOptions } from '@capacitor-firebase/remote-config';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { EventService } from '@services/event-service/event.service';
import { AFInit, AppsFlyer, AppsFlyerConsent } from 'appsflyer-capacitor-plugin';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SQLiteService } from '@services/sqlite.service';
import { getOpenedStoriesKey } from '@interfaces/storage.keys.interface';
import { UserService } from '@services/user-service/user.service';
import { initializeApp } from 'firebase/app';
import { register } from 'swiper/element/bundle';

register();

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  signedIn: boolean;
  user: any;
  greeting: string;
  storyTitle: any;
  public navi: any = navigator;
  connection: string;

  isPurchaseLoading = false;

  constructor(
    public ratingService: RatingService,
    public trainingService: TrainingService,
    public utilsService: UtilsService,
    private purchaseService: PurchaseService,
    private platform: Platform,
    private languageService: LanguageService,
    private storyService: StoryService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private storageService: StorageService,
    private wordService: WordService,
    private screensizeService: ScreensizeService,
    private deeplinks: Deeplinks,
    private navController: NavController,
    private alertController: AlertController,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private themeService: ThemeService,
    private onboardingService: OnboardingService,
    private eventService: EventService,
    private _sqlite: SQLiteService,
    private userService: UserService
  ) {
    PurchaseService.onLoadingUpdated().subscribe((loading) => {
      this.isPurchaseLoading = loading;
    });
  }

  async ngOnInit(): Promise<void> {
    this.initializeApp();
  }

  async initializeApp(): Promise<void> {
    this.platform.ready().then(async () => {
      this.preLoadLocalLabels();
      this.themeService.initTheme();
      if (this.utilsService.isWebVersion) {
        this.purchaseService.getCountryIP();
      }
      this.authService.getUser().then(async () => {
        try {
          if (this.authService.anonymousUserId == 'undefined' || this.authService.anonymousUserId == null) await this.authService.getUserID();
          await this.purchaseService.checkPremium(this.authService.anonymousUserId, true).then((res) => {
            if (this.purchaseService.voucherUser) {
              this.purchaseService.makeUserPremium({})
            }
          });
          this.initPlatform();
          this.initDeeplinks();
          this.initStorage();
          this.initBugsnag();
        } catch (error) {
          console.error("Error getting user", JSON.stringify(error));
        }
      }).catch(error => {
        console.log("Error getting user", JSON.stringify(error));
      });
      this.utilsService.watchDate();
      this.screensizeService.onResize(this.platform.width());
      setTimeout(() => {
        this.notificationService.OneSignalInit();
      }, 2000);
      this.authService.newAccount = false

      if (this.utilsService.isWebVersion) {
        interval(600000).subscribe(() => {
          this.purchaseService.getRevenueCatSubscription();
        })
      }

      interval(600000).subscribe(() => {
        this.authService.checkUserDevice().then((res) => {
          if (res.user?.stripeSubscriptionStatus == 'active' || res.user?.stripeSubscriptionStatus == 'trialing') {
            this.purchaseService.makeUserPremium({})
          }
        }).catch((error => {
          console.log("eror", error)
          if (error.status == 400) {
            this.authService.logout();
            this.showForcedLogoutAlert();
          }
        }));
      })

      //firebase experiments
      if (!environment.single && !this.utilsService.isWebVersion) {
        FirebaseRemoteConfig.setSettings({
          minimumFetchIntervalInSeconds: 300000
        } as SetSettingsOptions)
      }

      document.addEventListener('pause', () => {
        console.log('LISTENER: App is in the background 🤩');
        this.eventService.appPause();
      });

      document.addEventListener('resume', () => {
        console.log('LISTENER: App is in the foreground 🥸');
        this.eventService.appResume();
        try {
          this.trainingService.getTrainingSummary();
        } catch (error) {
          console.error("Error getting training summary:", error);
        }
        if (this.platform.is('android')) {
          window.audioFocus.requestFocus(() => {
            if (this.router.url.includes('article')) {
              this.utilsService.reloadAudio = true;
              console.log("DEBUG: audioFocus.requestFocus reloadAudio = true")
            }
            console.log("DEBUG: Audio Focus gained");
          }, () => {
            console.log("DEBUG: Audio Focus lost");
          });
        }
      });

      document.addEventListener("offline", () => {
        console.log("DEBUG: Offline", this.connection + this.utilsService.disconnected)
      });
      document.addEventListener("online", () => {
        if (this.utilsService.disconnected) {
          window.location.reload();
          this.utilsService.disconnected = false;
        }
      });

    });
  }

  async initPlatform(): Promise<void> {
    const platforms = this.platform.platforms();
    switch (true) {
      case platforms.includes('cordova'):
        await this.initNative();
        break;
      case platforms.includes('mobileweb'):
      case platforms.includes('desktop'):
        this.initWeb();
        break;
    }
  }

  async initNative(): Promise<void> {
    try {
      FirebaseAnalytics.setEnabled({ enabled: true });
      FacebookLogin.setAdvertiserTrackingEnabled({ enabled: true });
      FacebookLogin.setAdvertiserIDCollectionEnabled({ enabled: true });
    } catch (error) {
      console.error("initNative: Error initializing Firebase Analytics / Facebook Tracking", JSON.stringify(error));
    }

    if (this.platform.is('ios')) {
      this.userService.loadUserTrackingStatus();
    }

    if (MobileAccessibility) MobileAccessibility.usePreferredTextZoom(false); // Deactivates custom font size
    ScreenOrientation.lock('portrait');

    return new Promise((resolve, reject) => {
      this.purchaseService.configurePurchasing().then(async () => {
        this.initAppsFlyer();
        resolve();
      }).catch(() => {
        this.initAppsFlyer();
        reject();
      });
    });
  }

  async initAppsFlyer(): Promise<void> {
    if (this.platform.is('android') && this.purchaseService.user_EEA_perm && this.onboardingService.isActive) return

    const afConfig: AFInit = {
      appID: environment.appsflyer.appId, // replace with your app ID. 
      devKey: environment.appsflyer.devKey, // replace with your dev key. 
      isDebug: true,
      waitForATTUserAuthorization: 10, // for iOS 14 and higher
      minTimeBetweenSessions: 6, // default 5 sec
      registerOnDeepLink: true,
      registerConversionListener: true,
      registerOnAppOpenAttribution: false,
      deepLinkTimeout: 4000, // default 3000 ms
      useReceiptValidationSandbox: true, // iOS only
      useUninstallSandbox: true, // iOS only
    };

    try {
      await AppsFlyer.initSDK(afConfig);
    } catch (err) {
      console.error("initAppsFlyer: Error initializing AppsFlyer", JSON.stringify(err));
    }

    try {
      await AppsFlyer.getAppsFlyerUID();
    } catch (error) {
      console.error("initAppsFlyer: Error getting AppsFlyer UID", JSON.stringify(error));
    }

    try {
      AppsFlyer.setConsentData({ data: AppsFlyerConsent.forNonGDPRUser() })
    } catch (error) {
      console.error("initAppsFlyer: Error setting AppsFlyer consent data", JSON.stringify(error));
    }

    try {
      this.purchaseService.setRevenuecatAppsflyerAttributes();
    } catch (error) {
      console.error("initAppsFlyer: Error setting Revenuecat AppsFlyer attributes", JSON.stringify(error));
    }
  }

  initWeb(): void {
    this.purchaseService.configurePurchasing();
    initializeApp(environment.firebase)
    this.trackPageEvents();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  async initStorage() {
    this.ratingService.checkAppStarts();

    if (environment.single && environment.language == 'de' && !(await this.storageService.getIsReadleDataImported())) {
      await this.storageService.importReadleData();
      timer(100).subscribe(async () => {
        this.purchaseService.restorePurchase(false);
      });
    }

    await this.storageService.transferFromLocalStorage();
    this.languageService.checkLanguage().then(async (languageCode) => {

      this.wordService.loadWordSettings();
      this.storageService.sendRevenueCatAttributes();

      if (this.authService.token) {
        // 1. Validate token
        this.authService.validateToken().then(async (user) => {

          // 2. Load complete user data
          user = await this.authService.loadCompleteUserData(this.languageService.origin);
          this.authService.setUser(user);

          let openedStories: string | [] = await this._sqlite.getItem(getOpenedStoriesKey(this.languageService.origin)) as string;
          if (openedStories) openedStories = JSON.parse(openedStories);
          else openedStories = [];

          // 3. Check if user data needs to be imported from local storage
          if (user.data?.length == 0 && (this.storageService.favorites?.length > 0 || this.storageService.learnedStories?.length > 0 || openedStories.length > 0)) {


            if (!user.data || user.data?.length == 0 || (user.data && !user.data[0].training)) {
              if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
            }

            await this.authService.loadUserData();
            await this.storageService.initUserDataFromServer();
            return;
          }

          const convertedTrainingData = await this.storageService.getConvertedTrainingData();
          if (convertedTrainingData.length > 0) {
            if (!user.data || user.data?.length == 0 || (user.data && (!user.data[0].training || user.data[0].training?.length == 0))) {
              if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
            }
          }

          // Exsisting user that already have stored data on server and need to store the current state of training data
          if (!user.data || user.data?.length == 0 || (user.data && (!user.data.find(el => el.language == this.languageService.origin).training || user.data.find(el => el.language == this.languageService.origin).training?.length == 0))) {
            await this.storageService.getConvertedTrainingData();
          }
          await this.storageService.storeInitUserDataOnServer();
          if (!this.authService.user.trainingMigrationStart || this.authService.user.trainingMigrationStart == null) {
            setTimeout(async () => {
              await this.trainingService.migrateTrainingData();
            }, 50);
          }
          // 4. Use Data from Server
          this.storageService.initUserDataFromServer();

        }).catch(() => {
          // user token not valid -> comsider loggin out and display message to user
          console.log("USER_TOKEN_NOT_VALID")
        })
      }
      if (this.authService.user) {
        this.trainingService.getTrainingDeckfromServer()
        FirebaseAnalytics.setUserProperty({ key: "loggedin", value: 'true' });
      }
      else FirebaseAnalytics.setUserProperty({ key: "loggedin", value: 'false' });
    });

    this.ratingService.hasUserRated();
    this.purchaseService.hasUserShared();
    this.purchaseService.hasUserSharedUnlock();
    this.utilsService.checkAppearance();
    this.storageService.initUserSettings();
  }

  initDeeplinks() {
    console.log("initDeeplinks", JSON.stringify(this.deeplinks))
    this.deeplinks.route({
      '/article/:id': ArticlePage,
      '/article/:language/:id': ArticlePage,
      '/reset-password': ResetPasswordPage,
      '/training': TrainingPage,
      '/profile': ProfilePage,
      '/start-learning': CreditCardPurchasePage,
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log("initDeeplinks2", JSON.stringify(match))

      if (match.$link.path == '/reset-password') {
        this.navController.navigateForward('/reset-password?token=${match.$args.token}');
        return;
      }
      if (match.$link.path == '/training') {
        this.navController.navigateForward('/training');
        return;
      }
      if (match.$link.path == '/profile') {
        this.navController.navigateForward('/profile');
        return;
      }
      if (match.$link.path == '/start-learning') {
        this.navController.navigateForward('/start-learning');
        return;
      }

      this.notificationService.nextStoryFree = true;
      this.navController.navigateForward(match.$link.path)
    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });
  }

  async showForcedLogoutAlert() {
    if (this.authService.isForcedLogoutModalShown) return;
    this.authService.isForcedLogoutModalShown = true;
    this.alertController.create({
      backdropDismiss: false,
      header: this.languageService.label.txt_settings_logout,
      message: this.languageService.label.txt_device_limit_reached_forced_logout_title,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_logout_success_confirm,
          handler: () => {
            this.authService.isForcedLogoutModalShown = false;
            if (this.utilsService.isWebVersion) {
              // this.router.navigate(['/login']);
              // redirect to https://www.storytelling-app.com/login
              const lang = this.languageService.target.toLocaleLowerCase();

              switch (lang) {
                case 'fr':
                  window.location.href = 'https://langster.org/fr/';
                  break;
                case 'de':
                  window.location.href = 'https://langster.org/de/';
                  break;
                case 'en':
                  window.location.href = 'https://langster.org/en/';
                  break;
                case 'es':
                  window.location.href = 'https://langster.org/es/';
                  break;
                case 'ko':
                  window.location.href = 'https://langster.org/ko/';
                  break;
                case 'ja':
                  window.location.href = 'https://langster.org/ja/';
                  break;
                case 'uk':
                  window.location.href = 'https://langster.org/uk/';
                  break;
                case 'zh-hant':
                  window.location.href = 'https://langster.org/zh-hant/';
                  break;
                case 'zh-hans':
                  window.location.href = 'https://langster.org/zh-hans/';
                  break;
                default:
                  window.location.href = 'https://langster.org/en/';
              }
            }
          }
        }
      ]
    }).then(alert => {
      alert.present();
    })
  }

  initBugsnag() {
    Bugsnag.setUser(this.authService.anonymousUserId);
  }

  trackPageEvents() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url.includes('article/') && !item.url.includes('/memorize')) {
          const storyId = item.url.split('/');
          this.storyService.getStory(storyId[2]).subscribe(storyData => {
            this.storyTitle = storyData.titles[0].text;
            item.url = '/article/' + this.storyTitle.replace(/\s+/g, '-');
          });
        }
        setTimeout(() => {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
            external_ID: this.authService.anonymousUserId
          };
          this.gtmService.pushTag(gtmTag);
        }, 3000);
        this.storyTitle = "";
      }
    });
  }

  async preLoadLocalLabels() {
    try {
      let lang = await this.languageService.loadTargetLanguage();
      if (!lang) return;
      if (lang == "eng") lang = "en";
      this.languageService.loadLocalLocalizationFile(lang);
    } catch (error) {
      console.error("Error preloading local labels", JSON.stringify(error));
    }
  }

}