import { Component} from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';
import { UtilsService } from '@services/utils-service/utils.service';


@Component({
  selector: 'no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss'],
})
export class NoConnectionComponent {
  public navi: any = navigator;
  
  constructor(
    public languageService: LanguageService,
    public utilsService: UtilsService,

  ) { }

}
