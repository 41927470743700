import { Component } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { PurchaseOrigin } from 'app/enums/purchase_origin.enum';
import { PurchaseTracking } from 'app/enums/purchase_tracking.enum';
import { environment } from 'environments/environment';
import { ShopPage } from '../../pages/shop/shop.page';

@Component({
  selector: 'free-story-info',
  templateUrl: './free-story-info.component.html',
  styleUrls: ['./free-story-info.component.scss'],
})
export class FreeStoryInfoComponent {
  environment = environment;

  constructor(
    public languageService: LanguageService,
    public utilsService: UtilsService,
    public platform: Platform,
    public purchaseService: PurchaseService,
    public navController: NavController,
    public modalController: ModalController,
    // private routerOutlet: IonRouterOutlet,
  ) { }

  close(): void {
    this.utilsService.freeStoriesInfo = false;
  }

  async goToShop(): Promise<void> {
    this.purchaseService.setPurchaseOrigin(PurchaseOrigin.FREESTORYINFO);
    this.utilsService.trackingString = PurchaseTracking.FREESTORYINFO;
    this.utilsService.freeStoriesInfo = false;
    if (this.utilsService.isWebVersion) {
      const mode = this.platform.is('ios') ? 'ios' : 'md';
      const modal = await this.modalController.create({
        component: ShopPage,
        cssClass: (!this.platform.is('cordova') && !environment.mockNative) ? 'shop-modal-web' : 'shop-modal',
        mode: mode,
      });
      await modal.present();
    } else this.navController.navigateForward('/onboarding-shop');
  }
}
