import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '@services/language-service/language.service';
import { timer } from 'rxjs';
import { NavController, Platform } from '@ionic/angular';
import { environment } from 'environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage {
    inputEmail: string;
    inputNewPassword: string;
    isShowNewPassword: boolean = false;
    isPending: boolean = false;
    isSuccess: boolean = false;
    isError: boolean = false;
    isReset: boolean = false;
    isResetPending: boolean = false;
    isResetSuccess: boolean = false;
    isResetError: boolean = false;
    isRepeatPasswordError: boolean = false;
    resetToken = '';
    passwordForm: UntypedFormGroup;
    isReadle = false;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        public languageService: LanguageService,
        private platform: Platform,
        private navCtrl: NavController,
        private formBuilder: UntypedFormBuilder
    ) {
        this.passwordForm = this.formBuilder.group({
            password: ['', [
              Validators.required,
              Validators.minLength(8),
              Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])([a-zA-Z0-9\W]+)/)
            ]],
            passwordRepeat: ['', []]
          }, { validator: this.passwordMatchValidator });
          

        this.route.queryParams
            .subscribe(params => {
                if (params.token != undefined) {
                    this.resetToken = params.token;
                    this.isPending = false;
                    this.isSuccess = false;
                    this.isReset = true;
                    this.isResetPending = false;
                }

                if (params.readle) {
                    this.isReadle = true;
                }
            }
        );
    }

    ionViewDidEnter() {
        FirebaseAnalytics.setCurrentScreen({ screenName: 'screen_reset_password'});
    }

    sendLink() {
        this.isPending = true;
        this.http.post(`${environment.api}/user/forgot-password`, {
            email: this.inputEmail,
            userLanguage: this.languageService.target 
        }).subscribe((res) => {
            timer(500).subscribe(() => {
                this.isPending = false;
                this.isSuccess = true;
            });
        }, (err) => {
            timer(500).subscribe(() => {
                this.isPending = false;
                this.isSuccess = true;
            });
        });
    }

    resetPassword() {
        let password = this.password.value;
        let passwordRepeat = this.passwordRepeat.value;

        if (password != passwordRepeat) {
            this.isRepeatPasswordError = true;
            return;
        }
        
        if (this.passwordForm.invalid){
            return;
        }

        //http post request to server
        this.isResetPending = true;
        this.http.post(`${environment.api}/user/reset-password`, {
            resetToken: this.resetToken,
            password: password
        }).subscribe((res) => {
            timer(500).subscribe(() => {
                this.isResetPending = false;
                this.isReset = false;
                this.isResetSuccess = true;
                this.isResetError = false;
            });
        }, (err) => {
            timer(2000).subscribe(() => {
                this.isPending = false;
                this.isSuccess = false;
                this.isReset = true;
                this.isResetPending = false;
                this.isResetError = true;
            });
            console.log(err);
        });
    }

    launchMailApp() {
        if (this.platform.is('ios')) {
            AppLauncher.openUrl({ url: 'com.apple.mobilemail://' });
        } else if (this.platform.is('android')) {
            AppLauncher.openUrl({ url: 'com.google.android.gm' });
        }
    } 

    goToLogin() {
        this.navCtrl.back();
    }

    get password() {
        return this.passwordForm.get('password');
    }

    get passwordRepeat() {
        return this.passwordForm.get('passwordRepeat');
    }

    passwordMatchValidator(control: any): { [key: string]: boolean } | null {
        const password = control.get('password');
        const passwordRepeat = control.get('passwordRepeat');
      
        if (password.value !== passwordRepeat.value) {
          return { 'passwordMismatch': true };
        }
      
        return null;
      }

}