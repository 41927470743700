export enum StorageKeys {
    MIGRATION_COMPLETED = 'migrationCompleted',
    USER = 'user',
    SHOW_ANSWER = 'showAnswer',
    SWAP_SIDES_CARD = 'swapSidesCard',
    PLAY_AUDIO_AUTO = 'playAudioAuto',
    SHOW_ROMAJI_IN_TRAINING = 'showRomajiInTraining',
    SHOW_HIRAGANA_IN_TRAINING = 'showHiraganaInTraining',
    SHOW_PINYIN_IN_TRAINING = 'showPinyinInTraining',
    QUIZ_AUDIO = 'quizAudio',
    SETTING_HIGHLIGHT = 'settingHighlight',
    TUTORIAL = 'tutorial',
    SENTENCE_TRANSLATE = 'sentenceTranslate',
    AUDIO_SPEED = 'audioSpeed',
    UNDERLINE_TRAINING = 'underlineTraining',
    FONT_SETTING = 'settingsFont',
    FIRST_STORY_AI_TRANSLATIONS = 'firstStoryAITranslations',
    SHOW_HIRAGANA = 'showHiragana',
    SHOW_ROMAJI = 'showRomaji',
    SHOW_PINYIN = 'showPinyin',
    SHOW_FURIGANA = 'showFurigana',
    PWA_INSTALL_TRIGGERED = 'pwaInstallTriggered',
    ACCEPPTED_ALL_COOKIES = 'acceptedCookies',
    PERSONALIZED_COOKIES = 'personalizedCookies',
    DID_USER_OPEN_ARTICLE_BEFORE = 'didUserOpenArticleBefore',
    OPENED_STREAK = 'openedStreak',
    OPENED_TRAINING = 'openedTraining',
    SRS_TRAINING = 'srsTraining',
    USER_ID = 'langster-user-id',
    OPENED_STORIES_DE = 'LangsterOpenedStories_de',
    OPENED_STORIES_EN = 'LangsterOpenedStories_en',
    OPENED_STORIES_FR = 'LangsterOpenedStories_fr',
    OPENED_STORIES_ES = 'LangsterOpenedStories_es',
    OPENED_STORIES_JA = 'LangsterOpenedStories_ja',
    OPENED_STORIES_ZH_HANS = 'LangsterOpenedStories_zh-hans',
    STORAGE_KEY_TOKEN = 'langster-token',
    STORAGE_KEY_USER = 'langster-user',
    TARGET_LANGUAGE = 'targetLanguage',
    ORIGIN_LANGUAGE = 'originLanguage',
    USER_SHARED = 'userShared',
    USER_SHARED_UNLOCK = 'userSharedUnlock',
    APP_STARTS = 'appStarts',
    USER_RATED = 'userRated',
    USER_LEVEL = 'LangsterUserLevel',
    FRENCH_USER_LEVEL = 'FrenchUserLevel',
    SPANISH_USER_LEVEL = 'SpanishUserLevel',
    GERMAN_USER_LEVEL = 'GermanUserLevel',
    JAPANESE_USER_LEVEL = 'JapaneseUserLevel',
    CHINESE_USER_LEVEL = 'ChineseUserLevel',
    ENGLISH_USER_LEVEL = 'EnglishUserLevel',
    FAVORITE_STORIES_DE = 'LangsterFavoriteArticles_de',
    FAVORITE_STORIES_EN = 'LangsterFavoriteArticles_en',
    FAVORITE_STORIES_FR = 'LangsterFavoriteArticles_fr',
    FAVORITE_STORIES_ES = 'LangsterFavoriteArticles_es',
    FAVORITE_STORIES_JA = 'LangsterFavoriteArticles_ja',
    FAVORITE_STORIES_ZH_HANS = 'LangsterFavoriteArticles_zh-hans',
    LEARNED_STORIES_DE = 'LangsterLearnedStories_de',
    LEARNED_STORIES_EN = 'LangsterLearnedStories_en',
    LEARNED_STORIES_FR = 'LangsterLearnedStories_fr',
    LEARNED_STORIES_ES = 'LangsterLearnedStories_es',
    LEARNED_STORIES_JA = 'LangsterLearnedStories_ja',
    LEARNED_STORIES_ZH_HANS = 'LangsterLearnedStories_zh-hans',
    LEARNED_STORIES_FILTER_STATE_DE = 'LangsterLearnedArticlesFilterState_de',
    LEARNED_STORIES_FILTER_STATE_EN = 'LangsterLearnedArticlesFilterState_en',
    LEARNED_STORIES_FILTER_STATE_FR = 'LangsterLearnedArticlesFilterState_fr',
    LEARNED_STORIES_FILTER_STATE_ES = 'LangsterLearnedArticlesFilterState_es',
    LEARNED_STORIES_FILTER_STATE_JA = 'LangsterLearnedArticlesFilterState_ja',
    LEARNED_STORIES_FILTER_STATE_ZH_HANS = 'LangsterLearnedArticlesFilterState_zh-hans',
    STORAGE_NAME = 'storageName',
    UNLOCKED_STORY_BY_REVIEW = 'Langster_unlockedStoryByReview',
    UNLOCKED_STORY_BY_SHARE = 'Langster_unlockedStoryByShare',
    UNLOCKED_STORY_BY_FREE = 'Langster_unlockedStoryByFree',
    IS_READLE_DATA_IMPORTED = 'isReadleDataImported',
    LANGSTER_KEY = 'langsterKey',
    TRAINING_STACK = 'LANGSTER_TRAINING_STACK',
    TRAINING_STACK_DE = 'LANGSTER_TRAINING_STACK_de',
    TRAINING_STACK_EN = 'LANGSTER_TRAINING_STACK_en',
    TRAINING_STACK_FR = 'LANGSTER_TRAINING_STACK_fr',
    TRAINING_STACK_ES = 'LANGSTER_TRAINING_STACK_es',
    TRAINING_STACK_JA = 'LANGSTER_TRAINING_STACK_ja',
    TRAINING_STACK_ZH_HANS = 'LANGSTER_TRAINING_STACK_zh-hans',
    TRAINING_MIGRATED = 'trainingMigrated',
    APPEARANCE = 'langster-appearance',
    READLE_APPEARANCE = 'readle-appearance',
    USER_DID_OPEN_ARTICLE_BEFORE = 'userDidOpenArticleBefore',
    SETTING_NOTIFICATIONS = 'settingNotifications',
    TRANSLATIONS_LANGUAGE = 'translationsLanguage',
    PERMISSION_TRACKING = 'permissionTracking',
}

export interface StorageSchema {
    [StorageKeys.MIGRATION_COMPLETED]: boolean;
    [StorageKeys.USER]: string;
    [StorageKeys.SHOW_ANSWER]: boolean;
    [StorageKeys.SWAP_SIDES_CARD]: boolean;
    [StorageKeys.PLAY_AUDIO_AUTO]: boolean;
    [StorageKeys.SHOW_ROMAJI_IN_TRAINING]: boolean;
    [StorageKeys.SHOW_HIRAGANA_IN_TRAINING]: boolean;
    [StorageKeys.SHOW_PINYIN_IN_TRAINING]: boolean;
    [StorageKeys.QUIZ_AUDIO]: boolean;
    [StorageKeys.SETTING_HIGHLIGHT]: boolean;
    [StorageKeys.TUTORIAL]: boolean;
    [StorageKeys.SENTENCE_TRANSLATE]: boolean;
    [StorageKeys.AUDIO_SPEED]: number;
    [StorageKeys.UNDERLINE_TRAINING]: boolean;
    [StorageKeys.FONT_SETTING]: number;
    [StorageKeys.FIRST_STORY_AI_TRANSLATIONS]: boolean;
    [StorageKeys.SHOW_HIRAGANA]: boolean;
    [StorageKeys.SHOW_ROMAJI]: boolean;
    [StorageKeys.SHOW_PINYIN]: boolean;
    [StorageKeys.SHOW_FURIGANA]: boolean;
    [StorageKeys.PWA_INSTALL_TRIGGERED]: boolean;
    [StorageKeys.ACCEPPTED_ALL_COOKIES]: boolean;
    [StorageKeys.PERSONALIZED_COOKIES]: boolean;
    [StorageKeys.DID_USER_OPEN_ARTICLE_BEFORE]: boolean;
    [StorageKeys.OPENED_STREAK]: boolean;
    [StorageKeys.OPENED_TRAINING]: boolean;
    [StorageKeys.SRS_TRAINING]: boolean;
    [StorageKeys.USER_ID]: string;
    [StorageKeys.OPENED_STORIES_DE]: string;
    [StorageKeys.OPENED_STORIES_EN]: string;
    [StorageKeys.OPENED_STORIES_FR]: string;
    [StorageKeys.OPENED_STORIES_ES]: string;
    [StorageKeys.OPENED_STORIES_JA]: string;
    [StorageKeys.OPENED_STORIES_ZH_HANS]: string;
    [StorageKeys.STORAGE_KEY_TOKEN]: string;
    [StorageKeys.STORAGE_KEY_USER]: string;
    [StorageKeys.TARGET_LANGUAGE]: string;
    [StorageKeys.ORIGIN_LANGUAGE]: string;
    [StorageKeys.USER_SHARED]: boolean;
    [StorageKeys.USER_SHARED_UNLOCK]: boolean;
    [StorageKeys.APP_STARTS]: number;
    [StorageKeys.USER_RATED]: boolean;
    [StorageKeys.USER_LEVEL]: string;
    [StorageKeys.FRENCH_USER_LEVEL]: string;
    [StorageKeys.SPANISH_USER_LEVEL]: string;
    [StorageKeys.GERMAN_USER_LEVEL]: string;
    [StorageKeys.JAPANESE_USER_LEVEL]: string;
    [StorageKeys.CHINESE_USER_LEVEL]: string;
    [StorageKeys.ENGLISH_USER_LEVEL]: string;
    [StorageKeys.FAVORITE_STORIES_DE]: string;
    [StorageKeys.FAVORITE_STORIES_EN]: string;
    [StorageKeys.FAVORITE_STORIES_FR]: string;
    [StorageKeys.FAVORITE_STORIES_ES]: string;
    [StorageKeys.FAVORITE_STORIES_JA]: string;
    [StorageKeys.FAVORITE_STORIES_ZH_HANS]: string;
    [StorageKeys.LEARNED_STORIES_DE]: string;
    [StorageKeys.LEARNED_STORIES_EN]: string;
    [StorageKeys.LEARNED_STORIES_FR]: string;
    [StorageKeys.LEARNED_STORIES_ES]: string;
    [StorageKeys.LEARNED_STORIES_JA]: string;
    [StorageKeys.LEARNED_STORIES_ZH_HANS]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_DE]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_EN]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_FR]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_ES]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_JA]: string;
    [StorageKeys.LEARNED_STORIES_FILTER_STATE_ZH_HANS]: string;
    [StorageKeys.STORAGE_NAME]: string;
    [StorageKeys.UNLOCKED_STORY_BY_REVIEW]: string;
    [StorageKeys.UNLOCKED_STORY_BY_SHARE]: string;
    [StorageKeys.UNLOCKED_STORY_BY_FREE]: string;
    [StorageKeys.IS_READLE_DATA_IMPORTED]: boolean;
    [StorageKeys.ORIGIN_LANGUAGE]: string;
    [StorageKeys.LANGSTER_KEY]: string;
    [StorageKeys.TRAINING_STACK]: string;
    [StorageKeys.TRAINING_STACK_DE]: string;
    [StorageKeys.TRAINING_STACK_EN]: string;
    [StorageKeys.TRAINING_STACK_FR]: string;
    [StorageKeys.TRAINING_STACK_ES]: string;
    [StorageKeys.TRAINING_STACK_JA]: string;
    [StorageKeys.TRAINING_STACK_ZH_HANS]: string;
    [StorageKeys.TRAINING_MIGRATED]: boolean;
    [StorageKeys.APPEARANCE]: string;
    [StorageKeys.READLE_APPEARANCE]: string;
    [StorageKeys.USER_DID_OPEN_ARTICLE_BEFORE]: boolean;
    [StorageKeys.SETTING_NOTIFICATIONS]: boolean;
    [StorageKeys.TRANSLATIONS_LANGUAGE]: string;
    [StorageKeys.PERMISSION_TRACKING]: boolean;
}

export function getOpenedStoriesKey(language: string): StorageKeys | undefined {
    const languageToStorageKeyMap: { [key: string]: StorageKeys } = {
        'de': StorageKeys.OPENED_STORIES_DE,
        'en': StorageKeys.OPENED_STORIES_EN,
        'fr': StorageKeys.OPENED_STORIES_FR,
        'es': StorageKeys.OPENED_STORIES_ES,
        'ja': StorageKeys.OPENED_STORIES_JA,
        'zh-hans': StorageKeys.OPENED_STORIES_ZH_HANS,
    };
    return languageToStorageKeyMap[language];
}

export function getFavoriteStoriesKey(language: string): StorageKeys | undefined {
    const languageToStorageKeyMap: { [key: string]: StorageKeys } = {
        'de': StorageKeys.FAVORITE_STORIES_DE,
        'en': StorageKeys.FAVORITE_STORIES_EN,
        'fr': StorageKeys.FAVORITE_STORIES_FR,
        'es': StorageKeys.FAVORITE_STORIES_ES,
        'ja': StorageKeys.FAVORITE_STORIES_JA,
        'zh-hans': StorageKeys.FAVORITE_STORIES_ZH_HANS,
    };
    return languageToStorageKeyMap[language];
}

export function getLearnedStoriesKey(language: string): StorageKeys | undefined {
    const languageToStorageKeyMap: { [key: string]: StorageKeys } = {
        'de': StorageKeys.LEARNED_STORIES_DE,
        'en': StorageKeys.LEARNED_STORIES_EN,
        'fr': StorageKeys.LEARNED_STORIES_FR,
        'es': StorageKeys.LEARNED_STORIES_ES,
        'ja': StorageKeys.LEARNED_STORIES_JA,
        'zh-hans': StorageKeys.LEARNED_STORIES_ZH_HANS,
    };
    return languageToStorageKeyMap[language];
}

export function getLearnedStoriesFilterStateKey(language: string): StorageKeys | undefined {
    const languageToStorageKeyMap: { [key: string]: StorageKeys } = {
        'de': StorageKeys.LEARNED_STORIES_FILTER_STATE_DE,
        'en': StorageKeys.LEARNED_STORIES_FILTER_STATE_EN,
        'fr': StorageKeys.LEARNED_STORIES_FILTER_STATE_FR,
        'es': StorageKeys.LEARNED_STORIES_FILTER_STATE_ES,
        'ja': StorageKeys.LEARNED_STORIES_FILTER_STATE_JA,
        'zh-hans': StorageKeys.LEARNED_STORIES_FILTER_STATE_ZH_HANS,
    };
    return languageToStorageKeyMap[language];
}

export function getTrainingStackKey(language: string): StorageKeys | undefined {
    const languageToStorageKeyMap: { [key: string]: StorageKeys } = {
        'de': StorageKeys.TRAINING_STACK_DE,
        'en': StorageKeys.TRAINING_STACK_EN,
        'fr': StorageKeys.TRAINING_STACK_FR,
        'es': StorageKeys.TRAINING_STACK_ES,
        'ja': StorageKeys.TRAINING_STACK_JA,
        'zh-hans': StorageKeys.TRAINING_STACK_ZH_HANS,
    };
    return languageToStorageKeyMap[language];
}