import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Capacitor } from '@capacitor/core';
import { defineCustomElements as jeepSqlite} from 'jeep-sqlite/loader';

if (environment.production) {
  enableProdMode();
}

if (Capacitor.isNativePlatform()) {
  console.log('Capacitor is ready!');
}

jeepSqlite(window);
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
