import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  CapacitorSQLite, SQLiteDBConnection, SQLiteConnection,
  capSQLiteChanges, capSQLiteValues, capSQLiteResult,
  capNCDatabasePathResult,
  CapacitorSQLitePlugin
} from '@capacitor-community/sqlite';
import { environment } from 'environments/environment';
import { StorageKeys, StorageSchema } from '@interfaces/storage.keys.interface';

@Injectable()
export class SQLiteService {
  sqlite: SQLiteConnection;
  isService: boolean = false;
  platform: string;
  sqlitePlugin: CapacitorSQLitePlugin;
  native: boolean = false;
  connection: SQLiteDBConnection;
  debug: boolean = true;

  /**
   * Plugin Initialization
   */
  public async initializePlugin(): Promise<boolean> {
    try {
      this.platform = Capacitor.getPlatform();
      if (this.platform === 'ios' || this.platform === 'android') {
        this.native = true;
      }

      this.sqlitePlugin = CapacitorSQLite;
      this.sqlite = new SQLiteConnection(this.sqlitePlugin);
      this.isService = true;

      if (!this.native) {
        await this.initializePluginForWeb();
      }

      // Attempt to create the connection
      this.connection = await this.createConnection('app_data', false, 'no-encryption', 1);
      await this.connection.open();

      // Verify the connection state
      if (!(await this.connection.isDBOpen()).result) {
        throw new Error('Database connection is not open');
      }

      await this.createKeyValueTable();

      return true;
    } catch (error) {
      console.error('SQLite initialization error', error);
      return false;
    }
  }

  private async initializePluginForWeb(): Promise<void> {
    return new Promise((resolve, reject) => {
      window.addEventListener("DOMContentLoaded", async () => {
        try {
          // Create the 'jeep-sqlite' Stencil component
          const jeepSqliteElement = document.createElement("jeep-sqlite");
          document.body.appendChild(jeepSqliteElement);
          await customElements.whenDefined("jeep-sqlite");
          jeepSqliteElement.autoSave = true;

          // Initialize the Web store
          await this.sqlite.initWebStore();

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  private async createKeyValueTable(): Promise<void> {
    this.ensureConnectionIsOpen();
    const query = `
      CREATE TABLE IF NOT EXISTS key_value_store (
        key TEXT PRIMARY KEY,
        value TEXT,
        type TEXT
      );
    `;
    await this.connection.execute(query);
  }

  public async setItem<K extends keyof StorageSchema>(key: K, value: StorageSchema[K]): Promise<void> {
    try {
      this.ensureConnectionIsOpen();
      const valueType = typeof value;
      const valueStr = value.toString();

      const query = `
        INSERT OR REPLACE INTO key_value_store (key, value, type)
        VALUES (?, ?, ?);
      `;
      await this.connection.run(query, [key, valueStr, valueType]);
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getItem<K extends keyof StorageSchema>(key: K): Promise<StorageSchema[K] | null> {
    this.ensureConnectionIsOpen();
    const query = `
      SELECT value, type FROM key_value_store WHERE key = ?;
    `;
    const result = await this.connection.query(query, [key]);

    if (this.debug) console.log(`getItem: key: ${key}, result:`, result);

    if (result.values.length > 0) {
      const { value, type } = result.values[0];
      switch (type) {
        case 'number':
          return Number(value) as StorageSchema[K];
        case 'boolean':
          return (value === 'true') as StorageSchema[K];
        case 'string':
        default:
          return value as StorageSchema[K];
      }
    }
    return null;
  }

  public async removeItem<K extends keyof StorageSchema>(key: K): Promise<void> {
    this.ensureConnectionIsOpen();
    const query = `
      DELETE FROM key_value_store WHERE key = ?;
    `;
    await this.connection.run(query, [key]);
  }

  async isSecretStored(): Promise<capSQLiteResult> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.isSecretStored();
  }

  async setEncryptionSecret(passphrase: string): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.setEncryptionSecret(passphrase);
  }

  async changeEncryptionSecret(passphrase: string, oldpassphrase: string): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.changeEncryptionSecret(passphrase, oldpassphrase);
  }

  /**
   * get a non-conformed database path
   * @param folderPath
   * @param database
   * @returns Promise<capNCDatabasePathResult>
   * @since 3.3.3-1
   */
  async getNCDatabasePath(folderPath: string, database: string): Promise<capNCDatabasePathResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.getNCDatabasePath(folderPath, database);
  }

  /**
   * Create a non-conformed database connection
   * @param databasePath
   * @param version
   * @returns Promise<SQLiteDBConnection>
   * @since 3.3.3-1
   */
  async createNCConnection(databasePath: string, version: number): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    const db: SQLiteDBConnection = await this.sqlite.createNCConnection(databasePath, version);
    if (db == null) {
      throw new Error(`no db returned is null`);
    }
    return db;
  }

  /**
   * Close a non-conformed database connection
   * @param databasePath
   * @returns Promise<void>
   * @since 3.3.3-1
   */
  async closeNCConnection(databasePath: string): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeNCConnection(databasePath);
  }

  /**
   * Check if a non-conformed databaseconnection exists
   * @param databasePath
   * @returns Promise<capSQLiteResult>
   * @since 3.3.3-1
   */
  async isNCConnection(databasePath: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isNCConnection(databasePath);
  }

  /**
   * Retrieve a non-conformed database connection
   * @param databasePath
   * @returns Promise<SQLiteDBConnection>
   * @since 3.3.3-1
   */
  async retrieveNCConnection(databasePath: string): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveNCConnection(databasePath);
  }

  /**
   * Check if a non conformed database exists
   * @param databasePath
   * @returns Promise<capSQLiteResult>
   * @since 3.3.3-1
   */
  async isNCDatabase(databasePath: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isNCDatabase(databasePath);
  }

  /**
   * Create a connection to a database
   * @param database
   * @param encrypted
   * @param mode
   * @param version
   */
  async createConnection(database: string, encrypted: boolean, mode: string, version: number): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();

    if (this.platform === 'ios') {
      try {
        await this.sqlite.closeConnection(database, false);
      } catch (e) {
        console.log('Unable close db connection: ', e);
      }
    }

    try {
      return await this.sqlite.createConnection(database, encrypted, mode, version, false);
    } catch {
      throw new Error(`no db returned is null`);
    }
  }

  /**
   * Close a connection to a database
   * @param database
   */
  async closeConnection(database: string): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeConnection(database, false);
  }

  /**
   * Retrieve an existing connection to a database
   * @param database
   */
  async retrieveConnection(database: string): Promise<SQLiteDBConnection> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveConnection(database, false);
  }

  /**
   * Retrieve all existing connections
   */
  async retrieveAllConnections(): Promise<Map<string, SQLiteDBConnection>> {
    this.ensureConnectionIsOpen();
    return this.sqlite.retrieveAllConnections();
  }

  /**
   * Close all existing connections
   */
  async closeAllConnections(): Promise<void> {
    this.ensureConnectionIsOpen();
    return this.sqlite.closeAllConnections();
  }

  /**
   * Check if connection exists
   * @param database
   */
  async isConnection(database: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isConnection(database, false);
  }

  /**
   * Check Connections Consistency
   * @returns
   */
  async checkConnectionsConsistency(): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.checkConnectionsConsistency();
  }

  /**
   * Check if database exists
   * @param database
   */
  async isDatabase(database: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isDatabase(database);
  }

  /**
   * Get the list of databases
   */
  async getDatabaseList(): Promise<capSQLiteValues> {
    this.ensureConnectionIsOpen();
    return this.sqlite.getDatabaseList();
  }

  /**
   * Get Migratable databases List
   */
  async getMigratableDbList(folderPath?: string): Promise<capSQLiteValues> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();

    if (!folderPath || folderPath.length === 0) {
      throw new Error(`You must provide a folder path`);
    }
    return this.sqlite.getMigratableDbList(folderPath);
  }

  /**
   * Add "SQLite" suffix to old database's names
   */
  async addSQLiteSuffix(folderPath?: string, dbNameList?: string[]): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    const path: string = folderPath ? folderPath : 'default';
    const dbList: string[] = dbNameList ? dbNameList : [];
    return this.sqlite.addSQLiteSuffix(path, dbList);
  }

  /**
   * Delete old databases
   */
  async deleteOldDatabases(folderPath?: string, dbNameList?: string[]): Promise<void> {
    this.ensureIsNativePlatform();
    this.ensureConnectionIsOpen();
    const path: string = folderPath ? folderPath : 'default';
    const dbList: string[] = dbNameList ? dbNameList : [];
    return this.sqlite.deleteOldDatabases(path, dbList);
  }

  /**
   * Import from a Json Object
   * @param jsonstring
   */
  async importFromJson(jsonstring: string): Promise<capSQLiteChanges> {
    this.ensureConnectionIsOpen();
    return this.sqlite.importFromJson(jsonstring);
  }

  /**
   * Is Json Object Valid
   * @param jsonString Check the validity of a given Json Object
   */

  async isJsonValid(jsonString: string): Promise<capSQLiteResult> {
    this.ensureConnectionIsOpen();
    return this.sqlite.isJsonValid(jsonString);
  }

  /**
   * Copy databases from public/assets/databases folder to application databases folder
   */
  async copyFromAssets(overwrite?: boolean): Promise<void> {
    const mOverwrite: boolean = overwrite != null ? overwrite : true;
    this.ensureConnectionIsOpen();
    return this.sqlite.copyFromAssets(mOverwrite);
  }

  async initWebStore(): Promise<void> {
    this.ensureIsWebPlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.initWebStore();
  }

  /**
   * Save a database to store
   * @param database
   */
  async saveToStore(database: string): Promise<void> {
    this.ensureIsWebPlatform();
    this.ensureConnectionIsOpen();
    return this.sqlite.saveToStore(database);
  }

  private ensureConnectionIsOpen() {
    if (this.sqlite == null) {
      throw new Error(`no connection open`);
    }
  }

  private ensureIsNativePlatform() {
    if (!this.native) {
      throw new Error(`Not implemented for ${this.platform} platform`);
    }
  }

  private ensureIsWebPlatform() {
    if (this.platform !== 'web') {
      throw new Error(`Not implemented for ${this.platform} platform`);
    }
  }

  public async migrateIndexedDBToSQLite(): Promise<void> {
    try {
      const migrationStatus = await this.getItem(StorageKeys.MIGRATION_COMPLETED);
      if (migrationStatus) {
        console.log('Migration already completed');
        return;
      }
    } catch (error) {
      console.error("Error checking migration status", JSON.stringify(error));
    }

    console.log("Started migration ...")
    //this.ensureConnectionIsOpen();

    return new Promise((resolve, reject) => {
      try {
        const indexedDBRequest = indexedDB.open(environment.localDBName);
        indexedDBRequest.onsuccess = async (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          const objectStoreName = '_ionickv';

          if (!db.objectStoreNames.contains(objectStoreName)) {
            this.setItem(StorageKeys.MIGRATION_COMPLETED, true);
            console.log('✅ Nothing to migrate');

            resolve();
            return;
          }

          // Start a transaction and get all data from the object store
          const transaction = db.transaction([objectStoreName], 'readonly');
          const objectStore = transaction.objectStore(objectStoreName);
  
          const keysRequest = objectStore.getAllKeys();
          const valuesRequest = objectStore.getAll();
  
          keysRequest.onsuccess = async (event) => {
            const keys = (event.target as IDBRequest<IDBValidKey[]>).result;
            valuesRequest.onsuccess = async (event) => {
              const values = (event.target as IDBRequest<any[]>).result;
  
              // Insert data into SQLite using setItem
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = values[i];
                try {
                  if (value !== undefined && value !== null) {
                    await this.setItemForMigration(key as string, value);
                    console.log("✅", key, value);
                  } else {
                    console.log(`❌ value for '${key}' is ${value}`);
                  }
                } catch (error) {
                  console.error('❌', key, JSON.stringify(error));
                }
              } 
  
              this.setItem(StorageKeys.MIGRATION_COMPLETED, true);
              console.log('✅ Migration completed successfully');

              resolve();
            };
  
            valuesRequest.onerror = (event) => {
              console.error('Error reading values from IndexedDB', event);
              reject(event);
            };
          };
  
          keysRequest.onerror = (event) => {
            console.error('Error reading keys from IndexedDB', event);
            reject(event);
          };
        };
  
        indexedDBRequest.onerror = (event) => {
          console.error('Error opening IndexedDB', event);
          reject(event);
        };
      } catch (error) {
        console.error('Migration error', error);
        reject(error);
      }
    });
  }

  public async insertIntoSQLite(item: any): Promise<void> {
    const query = `INSERT INTO key_value_store (column1, column2) VALUES (?, ?)`;
    const values = [item.column1, item.column2];

    try {
      await this.connection.run(query, values);
    } catch (error) {
      console.error('Error inserting data into SQLite', error);
    }
  }

  public async dumpKeyValueTable(): Promise<void> {
    const query = `SELECT * FROM key_value_store`;

    try {
      const result = await this.connection.query(query);
      console.log('key_value_store Table Dump:', result.values);
    } catch (error) {
      console.error('Error dumping key_value_store table', error);
    }
  }

  private async setItemForMigration(key, value): Promise<void> {
    try {
      this.ensureConnectionIsOpen();
      const valueType = typeof value;
      const valueStr = value.toString();

      const query = `
        INSERT OR REPLACE INTO key_value_store (key, value, type)
        VALUES (?, ?, ?);
      `;

      await this.connection.run(query, [key, valueStr, valueType]);
    } catch (error) {
      Promise.reject(error);
    };
  }

}