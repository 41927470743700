import { Injectable } from '@angular/core';
import { Userdata } from '../../../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import OneSignal from 'onesignal-cordova-plugin';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { v4 as uuidv4 } from 'uuid';
import { OpenedStories } from '../../interfaces/story-opened';
import { LoginResult, SocialLogin } from '@capgo/capacitor-social-login';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { SQLiteService } from '@services/sqlite.service';
import { getOpenedStoriesKey, StorageKeys } from '@interfaces/storage.keys.interface';
import { jwtDecode } from 'jwt-decode';
import { FacebookLogin } from '@capacitor-community/facebook-login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public is_logged_in: boolean;
  public anonymousUser: any;
  public userdata: Userdata = {} as Userdata;
  public anonymousUserId: string | undefined;
  public signupMethod: string;
  public first_medium: string = '';
  public activeReport: boolean = false;
  public newAccount = false;
  public tmpErrToken: string;
  public tempEmail: string;
  public onboarding_register: boolean = false;
  // anonymous userId

  public user: any;
  public token: string;
  public isForcedLogoutModalShown: boolean = false;
  public webShopRegistration = false;
  public osTags: any;
  public fromChoosePlan: Boolean = false;
  public openedStoryList: OpenedStories[] = [];
  public couponDeepLink = false;
  public secondaryEmail = '';
  public createAccount = false;

  constructor(
    private _sqliteService: SQLiteService,
    private http: HttpClient,
    private platform: Platform,
    private gtmService: GoogleTagManagerService,
  ) {
    this.signupMethod = "social login";
  }

  async getUser() {
    await this.loadTokenFromStorage();
    await this.loadUserFromStorage();
    if (this.user) {
      return;
    }

    const fromStorage = await this._sqliteService.getItem(StorageKeys.USER_ID) as string;
    if (fromStorage) {
      this.anonymousUserId = fromStorage;
    } else {
      try {
        this.anonymousUserId = uuidv4();
        this._sqliteService.setItem(StorageKeys.USER_ID, this.anonymousUserId);
      } catch (error) {
        return;
      }
    }

    if (this.platform.is("cordova")) {
      try {
        OneSignal.login(this.anonymousUserId);
      } catch (error) {
        console.log("Error logging into OneSignal", JSON.stringify(error));
      }
    }

    return;
  }

  validateToken(): Promise<any> {
    return new Promise(async (resolve, reject) => {

      let deviceInfo;

      try {
        deviceInfo = await Device.getInfo();
      } catch (error) {
        console.log("Error getting device info", error);
      }

      this.http.post(`${environment.api}/user/token`, {
        deviceInfo: deviceInfo,
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  loadUserData(): Promise<any> {
    return new Promise((result, reject) => {
      this.http.get(`${environment.api}/user/${this.user.userId}`, { headers: this.authHeader }).subscribe((data: any) => {
        this.user = data;
        console.log("load data opened", JSON.stringify(this.user))
        result(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  loadCompleteUserData(language: string): Promise<any> {
    return new Promise((result, reject) => {
      if (!this.user?.userId) return reject("No user");
      var allTraining: boolean;
      if (this.user.trainingMigrated === true) {
        allTraining = false;
      } else {
        allTraining = true
      }
      this.http.get(`${environment.api}/user/${this.user.userId}?all=${allTraining}&language=${language}`, { headers: this.authHeader }).subscribe((data: any) => {
        this.user = data;
        result(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  addOpenedStory(language: string, storyId: any) {
    const date = new Date();

    this._sqliteService.getItem(getOpenedStoriesKey(language)).then((val: string) => {
      if (!storyId || !language) {
        console.log("error recording opened story")
        return;
      }
      const storiesOpenedData: OpenedStories = {
        "stories": [storyId],
        "date": date
      }
      if (val) {// has a openstories data
        this.openedStoryList = JSON.parse(val);
        console.log("opened1", this.openedStoryList)

        const openedStoriesObj = this.openedStoryList.find(el => {
          const eldate = new Date(el.date)
          return eldate.getFullYear() === date.getFullYear() &&
            eldate.getMonth() === date.getMonth() &&
            eldate.getDate() === date.getDate();
        });
        if (openedStoriesObj) {
          if (openedStoriesObj?.stories?.includes(storyId)) {
            return
          } else {
            openedStoriesObj?.stories?.push(storyId)
          }
        } else {
          this.openedStoryList.push(storiesOpenedData)
        }
      }
      else {
        this.openedStoryList = [{
          "stories": [storyId], "date": date
        }]
      }
      this._sqliteService.setItem(getOpenedStoriesKey(language), JSON.stringify(this.openedStoryList));
    })
  }

  async storeLearnersLanguage(code: string) {
  }

  public async getUserID() {
    await this.getUser();
    if (this.user?.userId) return this.user.userId;
    return this.anonymousUserId;
  }

  public setUserdata(obj) {
    this.userdata = obj;
  }

  public setToken(token: string) {
    this.token = token;
    this._sqliteService.setItem(StorageKeys.STORAGE_KEY_TOKEN, token);
  }

  public setUser(user: any) {
    console.log(user)
    this.user = user;
    this._sqliteService.setItem(StorageKeys.STORAGE_KEY_USER, JSON.stringify(user));
  }

  public setAnonymousUserId(id: string) {
    this.anonymousUserId = id;
    this._sqliteService.setItem(StorageKeys.USER_ID, id);
  }

  public loadTokenFromStorage() {
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(StorageKeys.STORAGE_KEY_TOKEN).then((token: string) => {
        if (token) {
          this.token = token;
          resolve(this.token)
        } else resolve(undefined);
      });
    })
  }

  public loadUserFromStorage() {
    return new Promise((resolve, reject) => {
      this._sqliteService.getItem(StorageKeys.STORAGE_KEY_USER).then((user: string) => {
        if (user) {
          this.user = JSON.parse(user);
          resolve(this.user)
        } else resolve(undefined);
      });
    })
  }

  public loadUserFromServer(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/user/${id}`, {
        headers: this.authHeader
      }).subscribe(user => {
        resolve(user);
      }, err => {
        reject(err);
      })
    });
  }

  async logout() {

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo();
    } catch (error) {
      console.log("Error getting device info", error);
    }
    FirebaseAnalytics.setUserProperty({ key: "loggedin", value: 'false'});
    this.http.post(`${environment.api}/user/logout`, {
      user: this.user,
      deviceInfo: deviceInfo
    }, { headers: this.authHeader }).toPromise().catch((err) => {
      console.log("Error logging out", err);
      FirebaseAnalytics.setUserProperty({ key: "loggedin",  value: 'true'});
    })

    this.user = null;
    this.token = '';
    this._sqliteService.removeItem(StorageKeys.STORAGE_KEY_TOKEN);
    this._sqliteService.removeItem(StorageKeys.STORAGE_KEY_USER);
    this._sqliteService.removeItem(StorageKeys.USER_ID);
    this.anonymousUserId = null;
  }

  loginWithFacebook(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      console.log("🌿 Logging in with Facebook");
      try {

        const FACEBOOK_PERMISSIONS = [
          'email',
        ];
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS  });

        if (result.accessToken) {
          // Login successful.
          console.log(`Facebook access token is ${result.accessToken.token}`);
        }
  
        this.loginWithFacebookToken(result.accessToken.token).then(data => {
          this.signupMethod = "facebook-sso";
          resolve(data);
        }).catch(error => {
          console.log("Error logging in with Facebook 2 ", JSON.stringify(error));
          reject(error);
        });
      } catch(error) {
        console.log("Error logging in with Facebook", error);
        return reject(error);
      } 
    });
  }

  loginWithFacebookToken(token: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      let deviceInfo: any = await Device.getInfo();
      deviceInfo = encodeURIComponent(JSON.stringify(deviceInfo));

      this.http.get<any>(`${environment.api}/auth/facebook/token?access_token=${token}&userId=${this.anonymousUserId}&deviceInfo=${deviceInfo}`).subscribe((data) => {
        if (data.token) this.setToken(data.token);
        if (data.user) {
          this.setUser(data.user);
          this.setAnonymousUserId(data.userId);
        }
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  }

  loginWithGoogle() {
    return new Promise(async (resolve, reject) => {
      try {
        const loginIOS = {
          iOSClientId: environment.googleAuthWebClientId, // the iOS client id
        }
  
        const loginAndroid = {
          webClientId: environment.googleTestClientId, // the Android  web client id
        }
  
        await SocialLogin.initialize({
          google: this.platform.is('ios') ? loginIOS : loginAndroid,
        });
  
        const res: LoginResult = await SocialLogin.login({
          provider: 'google',
          options: {
            scopes: ['email', 'profile'],
          },
        });

        console.log("🔵🌿 Google login result", JSON.stringify(res));
  
        const data = await this.loginWithGoogleToken(res.result.idToken)
        this.signupMethod = "google-sso";
        return resolve(data)
      } catch (error) {
        console.log("Error logging in with Google", JSON.stringify(error));
        return reject(error); 
      }
    })
  }

  loginWithGoogleToken(token: string) {
    return new Promise(async (resolve, reject) => {

      let deviceInfo: any = await Device.getInfo();
      deviceInfo = encodeURIComponent(JSON.stringify(deviceInfo));

      const urlExtention = environment.single ? `&slaOrigin=${environment.language}` : "";

      const header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      })
      this.http.get<any>(`${environment.api}/auth/google/token?access_token=${token}&userId=${this.anonymousUserId}&deviceInfo=${deviceInfo}${urlExtention}`, {
        headers: header
      }).subscribe((data) => {
        console.log("registered:", data);
        if (data.token) this.setToken(data.token);
        if (data.user) {
          this.setUser(data.user);
          this.setAnonymousUserId(data.userId);
        }
        resolve(data);
      }, (error) => {
        console.log(JSON.stringify(error));
        reject(error);
      });
    })
  }

  loginWithApple() {
    return new Promise(async (resolve, reject) => {

      try {
        let deviceInfo: any = await Device.getInfo();
  
        await SocialLogin.initialize({
          apple: {
            clientId: environment.googleAuthWebClientId, // it not used at os level only in plugin to know which provider initialize
          },
        });
  
        const res = await SocialLogin.login({
          provider: 'apple',
          options: {}
        });
  
        //const token = res.result.idToken;

        console.log("🔵🌿 Apple login result", JSON.stringify(res.result.profile));
      
        const decode = jwtDecode(res.result.accessToken.token);

        const r: AppleSignInResponse = {
          email: decode['email'],
          identityToken: res.result.accessToken.token,
          authorizationCode: res.result.idToken,
          user: decode['sub'],
        }

        const tokenValidationRequest: any = {
          provider: res.provider,
          response: r,
          userId: this.anonymousUserId,
          deviceInfo: deviceInfo,
          slaOrigin: environment.single ? environment.language : undefined
        }

        this.http.post<any>(`${environment.api}/auth/apple`, tokenValidationRequest).subscribe(data => {
          if (data.token) this.setToken(data.token);
          if (data.user) {
            this.setUser(data.user);
            this.setAnonymousUserId(data.userId);
          }
          this.signupMethod = "apple-sso";
          return resolve(data);
        }, (err) => {
          console.log("Error logging in with Apple", JSON.stringify(err));
          return reject(err)
        })
      } catch (error) {
        console.log("Error logging in with Apple", error);
        return reject(error);
      }
    });
  }

  storeUserData(userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // rejcet when no user is logged in
      if (!this.user || !this.token) return resolve({});
      // send user data to server
      this.http.put<any>(`${environment.api}/user`, userData, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  storeUserLanguageData(userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // rejcet when no user is logged in
      if (!this.user || !this.token) return reject("No token available");
      // send user data to server
      this.http.put<any>(`${environment.api}/user/data`, userData, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  successfullLogin(data: any) {
    this.setToken(data.token);
    this.setUser(data.user);
    this.setAnonymousUserId(data.anonymousUserId);
  }

  removeAccount() {
    return new Promise((resolve, reject) => {
      this.http.delete<any>(`${environment.api}/user`, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  async checkUserDevice(): Promise<any> {

    return new Promise(async (resolve, reject) => {
      if (!this.token) return resolve({});

      let deviceInfo = await Device.getInfo();

      this.http.post<any>(`${environment.api}/user/devices/validate`, {
        deviceInfo: deviceInfo
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data)
      }, (error) => {
        reject(error)
        return;
      });
    })
  }

  async addUserDevice(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.token) return resolve({});

      let deviceInfo = await Device.getInfo();

      this.http.post<any>(`${environment.api}/user/devices/add`, {
        deviceInfo: deviceInfo
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error)
      });
    })
  }

  async removeOldestDevice(tmpToken: string): Promise<any> {

    return new Promise((resolve, reject) => {
      const header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${tmpToken ? tmpToken : this.tmpErrToken}`,
      });

      this.http.post<any>(`${environment.api}/user/devices/removeOldest`, {}, { headers: header }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  get authHeader() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token ? this.token : environment.token}`,
    });
  }

  NewUserCheck() {
    setTimeout(() => { //wait for the user data
      //get date of when user was created
      const createdDate = new Date(this.user.createdAt);
      //get current date
      const date = new Date();
      //calc if the time difference is more than or within 10 minutes
      const timeDiff = Math.abs(createdDate.getTime() - date.getTime());
      if (timeDiff <= 600000) {
        //if it is within 10 minutes then the account was recently created, we sent the account created event to GA4
        console.log("apple new account", date);
        this.newAccount = true;
      } else console.log("apple old account", date);
    }, 300)
  }

  trackAccountCreated() {
    if (this.platform.is('cordova')) {
      if (this.user.email) OneSignal.User.addEmail(this.user.email);
      if (this.user.userId) OneSignal.login(this.user.userId);
    }
    //if first med already assigned, means this function was already called and prevents double sending of GA
    if (this.first_medium == 'web' || this.first_medium == 'app') return
    if (!this.platform.is('cordova')) {
      this.first_medium = 'web';
    } else {
      this.first_medium = 'app';
    }
    setTimeout(() => { //wait for the user data
      //get date of when user was created
      const createdDate = new Date(this.user.createdAt);
      //get current date
      const date = new Date();
      //calc if the time difference is more than or within 10 minutes
      const timeDiff = Math.abs(createdDate.getTime() - date.getTime());
      if (timeDiff <= 600000) {
        //if it is within 10 minutes then the account was recently created, we sent the account created event to GA4
        if (!this.platform.is("cordova")) {
          console.log(this.user);
          const gtmTag = {
            event: 'account_created',
            category: 'signup completed',
            action: this.signupMethod,
          }
          this.gtmService.pushTag(gtmTag);
        }
        else FirebaseAnalytics.logEvent({ name: "account_created", params: { method: this.signupMethod }});
        this.newAccount = true;
        console.log("apple trackAccountCreated", date, this.newAccount);
      }
    }, 300)
  }
}

export interface AppleSignInResponse {
  /**
   * The user’s email address i.e. abc@privaterelay.appleid.com
   */
  email?: string;
  /**
   * An arbitrary string that your app provided to the request that generated the credential
   */
  state?: string;
  /**
   * A JSON Web Token (JWT) that securely communicates information about the user to your app
   */
  identityToken: string;
  /**
   * A short-lived token used by your app for proof of authorization when interacting with the app's server counterpart
   */
  authorizationCode: string;
  /**
   * The user's name
   *
   * @see https://developer.apple.com/documentation/foundation/nspersonnamecomponents?language=objc
   */
  fullName?: string;
  /**
   * An identifier associated with the authenticated user
   */
  user?: string;
}